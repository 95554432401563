define('soundmondo-client/services/leaderboard', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    leaderboardURL: null,
    reface: null,
    ordering: null,
    lookup: null,

    buildurl: function buildurl() {
      if (this.get('leaderboardURL')) {
        return ENV['default'].APP.API_ENDPOINT + this.get('leaderboardURL');
      }
    },

    byId: function byId(id) {
      var url = this.buildurl(),
          lookup = this.get('lookup'),
          PromiseObject = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin);

      url += '?' + lookup + '=' + id;

      return PromiseObject.create({
        promise: Ember['default'].$.getJSON(url).then(function (data) {
          if (data.results && data.results.length > 0) {
            return data.results[0];
          }

          return {};
        })
      });
    },

    allTime: Ember['default'].computed('reface', 'ordering', function () {
      var PromiseObject = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin),
          reface = this.get('reface'),
          url = this.buildurl(),
          lookup = this.get('lookup');

      if (!Ember['default'].isEmpty(url)) {
        var qs = '';
        if (reface) {
          qs = 'reface=' + reface;
        }

        qs += '&ordering=-score';

        if (!Ember['default'].isEmpty(qs)) {
          url += '?' + qs;
        }
      }

      return PromiseObject.create({
        promise: Ember['default'].$.getJSON(url).then(function (data) {
          return data.results.map(function (item) {
            item[lookup] = item[lookup].match(/\/\d+\//g)[0].replace(/\//g, '');
            return item;
          });
        })
      });
    })

  });

});