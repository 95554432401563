define('soundmondo-client/services/featured-user', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    store: Ember['default'].inject.service(),

    allFeatures: Ember['default'].computed(function () {
      return this.get('store').findAll('feature');
    }),

    columnCount: Ember['default'].computed(function () {
      var PromiseObject = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin);

      return PromiseObject.create({
        promise: this.get('store').findAll('feature').then(function (data) {
          if (data.content.length <= 0) {
            return 12;
          } else {
            return Math.round(12 / data.content.length);
          }
        })
      });
    })
  });

});