define('soundmondo-client/helpers/strip-left', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.stripLeft = stripLeft;

  function stripLeft(params /*, hash*/) {
    var str = params[0],
        substr = params[1];
    return str.split(substr).splice(-1)[0];
  }

  exports['default'] = Ember['default'].Helper.helper(stripLeft);

});