define('soundmondo-client/tests/unit/models/voice-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('voice', 'Unit | Model | voice', {
    // Specify the other units that are required for this test.
    needs: ['model:background', 'model:user']
  });

  ember_qunit.test('it exists', function (assert) {
    var model = this.subject();
    // var store = this.store();
    assert.ok(!!model);
  });

});