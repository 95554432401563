define('soundmondo-client/components/reface-component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    devices: Ember['default'].inject.service('devices'),
    sync: false,
    previousVoice: null,

    setCurrentDevice: function setCurrentDevice() {
      var _this = this;
      Ember['default'].run.schedule('sync', _this, function () {
        var currentDevice = _this.get('devices').get('currentDevice');
        if (currentDevice) {
          var deviceId = currentDevice.inputId + ':' + currentDevice.outputId;
          window.reface_panel.setCurrentDevice(deviceId, function (ev) {
            if (ev.status) {
              _this.syncReface();
            } else {
              // Log device and event when status is false.
              console.log(currentDevice, ev);
            }
          });
        }
      });
    },

    syncReface: function syncReface() {
      var _this = this,
          sync = this.get('sync'),
          dataDeviceName = this.get('voice.voice.reface'),
          sysex = this.get('voice.voice.sysex'),
          data = null;

      if (sysex) {
        data = this.convertSysEx(sysex);
      }

      // Convert MONTAGE <=> MODX data
      // reface_panel の保有するデバイス情報を参照する
      //var inputDevice = _this.get('devices').get('currentDevice').inputDevice;
      var currentDevice = window.reface_panel.getCurrentDevice();
      if (currentDevice) {
        var currentDeviceName = currentDevice.name;

        // MODX/MODXPLUS の変換
        if (/MODX\dPLUS/.test(currentDevice.model)) {
          currentDeviceName = 'MODXPLUS';
        }

        if (typeof dataDeviceName === 'undefined') {
          dataDeviceName = '';
        }

        // MONTAGE M のデータはコンバートしない
        if (currentDeviceName.indexOf('MONTAGE M') === -1) {
          // MODX/MODXPLUS は どちらも indexOf('MODX') にヒットする
          if (dataDeviceName.indexOf('MONTAGE') > -1 && currentDeviceName.indexOf('MODX') > -1) {
            convert_2d_u8_bulk_montage2modx(data);
          }
          if (dataDeviceName.indexOf('MODX') > -1 && currentDeviceName.indexOf('MONTAGE') > -1) {
            convert_2d_u8_bulk_modx2montage(data);
          }
        }
      }

      Ember['default'].run.schedule('sync', _this, function () {
        if (sync && data) {
          _this.validateDataFormatVersion(data, function () {
            window.reface_panel.getVoice(function (ev) {
              _this.set('devices.previousVoice', ev.sysEx);
              if (ev.status) {
                window.reface_panel.setVoice(data);
              }
            });
          });
        }
      });
    },

    validateDataFormatVersion: function validateDataFormatVersion(data, onValid) {
      var dataformatversion = window.reface_panel.checkDataFormatVersion(data);

      // NOTE: When debugging version error, uncomment the line below
      // dataformatversion = 999.99;

      var event = new Event('versionInvalid');
      window.reface_panel.getInstFormatVersion(function (result) {
        if (result.status) {
          if (result.instformatversion < dataformatversion) {
            window.dispatchEvent(event);
          } else {
            onValid.call();
          }
        } else {
          console.warn('[WARN] getInstFormatVersion failed.', result);
          window.dispatchEvent(event);
        }
      }, 300);
    },

    convertSysEx: function convertSysEx(sysex) {
      return sysex.map(function (obj) {
        return new Uint8Array(Object.keys(obj).map(function (k) {
          return obj[k];
        }));
      });
    },

    didInsertElement: function didInsertElement() {
      var cachedRefacePanel = this.get('devices').get('_refacePanelEl');
      if (cachedRefacePanel) {
        var refaceEl = document.querySelector('#reface_panel'),
            refaceContainer = document.querySelector('#reface_container');
        refaceEl.parentNode.removeChild(refaceEl);
        // refaceEl.unbindAll();
        refaceContainer.appendChild(cachedRefacePanel);
        this.syncReface();
      }
      window.addEventListener('connection-updated', this.setCurrentDevice.bind(this));
    },

    removeReface: function removeReface(refaceEl) {
      refaceEl.parentNode.removeChild(refaceEl);
      // refaceEl.unbindAll();
      this.get('devices').set('_refacePanelEl', refaceEl);
      window.removeEventListener('connection-updated', this.setCurrentDevice.bind(this));
    },

    willClearRender: function willClearRender() {
      var refaceEl = document.querySelector('#reface_panel'),
          previousVoice = this.get('devices.previousVoice'),
          _this = this;
      if (previousVoice) {
        refaceEl.setVoice(previousVoice, function () {
          _this.removeReface(refaceEl);
        });
      } else {
        this.removeReface(refaceEl);
      }
    }
  });

});