define('soundmondo-client/components/tag-box', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tags: Ember['default'].inject.service(),
    tagList: Ember['default'].computed.alias('tags.tagList'),

    willClearRender: function willClearRender() {
      this.get('tagList').forEach(function (tag) {
        tag.set('selected', false);
      });
    },

    didRender: function didRender() {
      var selected = this.get('selected');

      if (!Ember['default'].isArray(selected)) {
        selected = [selected];
      }

      this.get('tagList').forEach(function (tag) {
        if (selected.contains(tag.get('name'))) {
          tag.set('selected', true);
        } else {
          tag.set('selected', false);
        }
      });
    },

    actions: {
      tagToggled: function tagToggled(tag) {
        if (this.get('single')) {
          this.get('tagList').forEach(function (tag) {
            tag.set('selected', false);
          });
        }

        this.sendAction('action', tag);
      }
    }
  });

});