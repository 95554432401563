define('soundmondo-client/helpers/open-new-row', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.openNewRow = openNewRow;

  function openNewRow(index) {
    if (index % 2 === 0) {
      return new Ember['default'].Handlebars.SafeString('<div class="row">');
    }
  }

  exports['default'] = Ember['default'].Helper.helper(openNewRow);

});