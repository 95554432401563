define('soundmondo-client/tests/unit/helpers/open-new-row-test', ['soundmondo-client/helpers/open-new-row', 'qunit'], function (open_new_row, qunit) {

  'use strict';

  qunit.module('Unit | Helper | open new row');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = open_new_row.openNewRow(42);
    assert.ok(result);
  });

});