define('soundmondo-client/services/tags', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    store: Ember['default'].inject.service(),
    tagList: Ember['default'].computed(function () {
      return this.get('store').findAll('tag');
    })
  });

});