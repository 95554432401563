define('soundmondo-client/controllers/voices/view', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service(),
    updatedFavoriteCount: null,
    canEdit: Ember['default'].computed('session.secure.id', 'model.voice.user.id', function () {
      var userId = this.get('session.secure.id'),
          voiceUserId = this.get('model.voice.user.id');

      return userId == voiceUserId; // jshint ignore:line
    }),

    queryParams: ['incompatibleDataFormat', 'fromRanking'],

    actions: {
      favorite: function favorite() {
        var _this = this,
            token = this.get('session.secure.token'),
            voiceId = this.get('model.voice.id'),
            adapter = this.store.adapterFor('application'),
            url = adapter.buildURL('voice', voiceId) + 'favorite/';

        if (token) {
          Ember['default'].$.ajax({
            url: url,
            method: 'PUT',
            data: {},
            contentType: false,
            processData: false,
            headers: {
              'Authorization': 'Token ' + token
            }
          }).then(function (response) {
            _this.set('updatedFavoriteCount', response.count);
          }, function () {});
        }
      }
    }
  });

});