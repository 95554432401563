define('soundmondo-client/templates/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "soundmondo-client/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment(" Top margin instead of Carousel ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"style","min-height: 50px; margin-bottom: 20px; border: 1px solid transparent;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 12
              },
              "end": {
                "line": 78,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","feature.fullName",["loc",[null,[77,14],[77,34]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 6
            },
            "end": {
              "line": 85,
              "column": 6
            }
          },
          "moduleName": "soundmondo-client/templates/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","featured-user");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"alt","profile");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","text-center sticker-title");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","featured-comments");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1]);
          var element19 = dom.childAt(element18, [1, 1]);
          var element20 = dom.childAt(element18, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element18, 'class');
          morphs[1] = dom.createAttrMorph(element19, 'src');
          morphs[2] = dom.createMorphAt(element20,1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["col-md-",["get","featuredUser.columnCount.content",["loc",[null,[71,29],[71,61]]]],"  col-sm-",["get","featuredUser.columnCount.content",["loc",[null,[71,74],[71,106]]]]]]],
          ["attribute","src",["concat",[["get","feature.imageUrl",["loc",[null,[73,24],[73,40]]]]]]],
          ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","feature.userId",["loc",[null,[76,57],[76,71]]]]],["loc",[null,[76,38],[76,72]]]]],[],0,null,["loc",[null,[76,12],[78,24]]]],
          ["content","feature.comments",["loc",[null,[81,14],[81,36]]]]
        ],
        locals: ["feature"],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 10
              },
              "end": {
                "line": 110,
                "column": 70
              }
            },
            "moduleName": "soundmondo-client/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 122,
                      "column": 24
                    },
                    "end": {
                      "line": 124,
                      "column": 24
                    }
                  },
                  "moduleName": "soundmondo-client/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"width","70");
                  dom.setAttribute(el1,"height","70");
                  dom.setAttribute(el1,"alt","profile");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element11, 'src');
                  return morphs;
                },
                statements: [
                  ["attribute","src",["concat",[["get","item.photo",["loc",[null,[123,75],[123,85]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 124,
                      "column": 24
                    },
                    "end": {
                      "line": 126,
                      "column": 24
                    }
                  },
                  "moduleName": "soundmondo-client/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"width","70");
                  dom.setAttribute(el1,"height","70");
                  dom.setAttribute(el1,"alt","profile");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element10, 'src');
                  return morphs;
                },
                statements: [
                  ["attribute","src",["concat",[["get","item.gravatar",["loc",[null,[125,75],[125,88]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 22
                  },
                  "end": {
                    "line": 127,
                    "column": 22
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.photo",["loc",[null,[122,30],[122,40]]]]],[],0,1,["loc",[null,[122,24],[126,31]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 129,
                    "column": 24
                  },
                  "end": {
                    "line": 131,
                    "column": 24
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","truncate-text",[["get","item.display_name",["loc",[null,[130,42],[130,59]]]]],["limit",21],["loc",[null,[130,26],[130,70]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 138,
                    "column": 26
                  },
                  "end": {
                    "line": 140,
                    "column": 26
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","truncate-text",[["get","item.name",["loc",[null,[139,44],[139,53]]]]],["limit",21],["loc",[null,[139,28],[139,64]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 16
                },
                "end": {
                  "line": 159,
                  "column": 16
                }
              },
              "moduleName": "soundmondo-client/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","rank");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","profile");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","display-name");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","info");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","name");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","description");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","stats");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","download-count");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5,"src","/assets/images/sync-icon.png");
              dom.setAttribute(el5,"alt","download");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","favorite-count");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5,"src","/assets/images/favorite-icon.png");
              dom.setAttribute(el5,"alt","download");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","updated");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var element13 = dom.childAt(element12, [5]);
              var element14 = dom.childAt(element12, [7]);
              var element15 = dom.childAt(element14, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createAttrMorph(element12, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element12, [3]),1,1);
              morphs[2] = dom.createMorphAt(element13,1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
              morphs[4] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
              morphs[5] = dom.createMorphAt(dom.childAt(element14, [3, 1]),1,1);
              morphs[6] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element15, [1]),3,3);
              morphs[8] = dom.createMorphAt(dom.childAt(element15, [3]),3,3);
              morphs[9] = dom.createMorphAt(dom.childAt(element15, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["item row rank-",["get","item.rank",["loc",[null,[115,46],[115,55]]]]]]],
              ["content","item.rank",["loc",[null,[118,22],[118,35]]]],
              ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","item.user_id",["loc",[null,[121,67],[121,79]]]]],["loc",[null,[121,48],[121,80]]]]],[],0,null,["loc",[null,[121,22],[127,34]]]],
              ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","item.user_id",["loc",[null,[129,69],[129,81]]]]],["loc",[null,[129,50],[129,82]]]]],[],1,null,["loc",[null,[129,24],[131,36]]]],
              ["content","item.device",["loc",[null,[135,26],[135,41]]]],
              ["block","link-to",["voices.view",["get","item.id",["loc",[null,[138,51],[138,58]]]],["subexpr","query-params",[],["fromRanking",1],["loc",[null,[138,59],[138,87]]]]],[],2,null,["loc",[null,[138,26],[140,38]]]],
              ["inline","truncate-text",[["get","item.description",["loc",[null,[144,40],[144,56]]]]],["limit",169],["loc",[null,[144,24],[144,68]]]],
              ["content","item.download_count",["loc",[null,[149,26],[149,49]]]],
              ["content","item.favorite_count",["loc",[null,[153,26],[153,49]]]],
              ["content","item.updated_at",["loc",[null,[155,46],[155,65]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 14
              },
              "end": {
                "line": 160,
                "column": 14
              }
            },
            "moduleName": "soundmondo-client/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.le5",["loc",[null,[114,22],[114,30]]]]],[],0,null,["loc",[null,[114,16],[159,23]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 173,
                      "column": 24
                    },
                    "end": {
                      "line": 175,
                      "column": 24
                    }
                  },
                  "moduleName": "soundmondo-client/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"width","70");
                  dom.setAttribute(el1,"height","70");
                  dom.setAttribute(el1,"alt","profile");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element5, 'src');
                  return morphs;
                },
                statements: [
                  ["attribute","src",["concat",[["get","item.photo",["loc",[null,[174,75],[174,85]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 175,
                      "column": 24
                    },
                    "end": {
                      "line": 177,
                      "column": 24
                    }
                  },
                  "moduleName": "soundmondo-client/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"width","70");
                  dom.setAttribute(el1,"height","70");
                  dom.setAttribute(el1,"alt","profile");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element4, 'src');
                  return morphs;
                },
                statements: [
                  ["attribute","src",["concat",[["get","item.gravatar",["loc",[null,[176,75],[176,88]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 172,
                    "column": 22
                  },
                  "end": {
                    "line": 178,
                    "column": 22
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.photo",["loc",[null,[173,30],[173,40]]]]],[],0,1,["loc",[null,[173,24],[177,31]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 180,
                    "column": 24
                  },
                  "end": {
                    "line": 182,
                    "column": 24
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","truncate-text",[["get","item.display_name",["loc",[null,[181,42],[181,59]]]]],["limit",21],["loc",[null,[181,26],[181,70]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 189,
                    "column": 26
                  },
                  "end": {
                    "line": 191,
                    "column": 26
                  }
                },
                "moduleName": "soundmondo-client/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","truncate-text",[["get","item.name",["loc",[null,[190,44],[190,53]]]]],["limit",21],["loc",[null,[190,28],[190,64]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 165,
                  "column": 16
                },
                "end": {
                  "line": 210,
                  "column": 16
                }
              },
              "moduleName": "soundmondo-client/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","rank");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","profile");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","display-name");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","info");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","name");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","description");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","stats");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","download-count");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5,"src","/assets/images/sync-icon.png");
              dom.setAttribute(el5,"alt","download");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","favorite-count");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5,"src","/assets/images/favorite-icon.png");
              dom.setAttribute(el5,"alt","download");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","updated");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(element6, [5]);
              var element8 = dom.childAt(element6, [7]);
              var element9 = dom.childAt(element8, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createAttrMorph(element6, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
              morphs[2] = dom.createMorphAt(element7,1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
              morphs[4] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
              morphs[5] = dom.createMorphAt(dom.childAt(element8, [3, 1]),1,1);
              morphs[6] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element9, [1]),3,3);
              morphs[8] = dom.createMorphAt(dom.childAt(element9, [3]),3,3);
              morphs[9] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["item row rank-",["get","item.rank",["loc",[null,[166,46],[166,55]]]]]]],
              ["content","item.rank",["loc",[null,[169,22],[169,35]]]],
              ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","item.user_id",["loc",[null,[172,67],[172,79]]]]],["loc",[null,[172,48],[172,80]]]]],[],0,null,["loc",[null,[172,22],[178,34]]]],
              ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","item.user_id",["loc",[null,[180,69],[180,81]]]]],["loc",[null,[180,50],[180,82]]]]],[],1,null,["loc",[null,[180,24],[182,36]]]],
              ["content","item.device",["loc",[null,[186,26],[186,41]]]],
              ["block","link-to",["voices.view",["get","item.id",["loc",[null,[189,51],[189,58]]]],["subexpr","query-params",[],["fromRanking",1],["loc",[null,[189,59],[189,87]]]]],[],2,null,["loc",[null,[189,26],[191,38]]]],
              ["inline","truncate-text",[["get","item.description",["loc",[null,[195,40],[195,56]]]]],["limit",169],["loc",[null,[195,24],[195,68]]]],
              ["content","item.download_count",["loc",[null,[200,26],[200,49]]]],
              ["content","item.favorite_count",["loc",[null,[204,26],[204,49]]]],
              ["content","item.updated_at",["loc",[null,[206,46],[206,65]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 14
              },
              "end": {
                "line": 211,
                "column": 14
              }
            },
            "moduleName": "soundmondo-client/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.ge6",["loc",[null,[165,22],[165,30]]]]],[],0,null,["loc",[null,[165,16],[210,23]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 6
            },
            "end": {
              "line": 216,
              "column": 6
            }
          },
          "moduleName": "soundmondo-client/templates/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-md-6 le5");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("hr");
          dom.setAttribute(el4,"class","last");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-md-6 ge6");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("hr");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var element17 = dom.childAt(element16, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element16, 'id');
          morphs[1] = dom.createAttrMorph(element16, 'class');
          morphs[2] = dom.createMorphAt(element16,1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element17, [1]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element17, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","id",["concat",[["get","by.id",["loc",[null,[109,19],[109,24]]]]]]],
          ["attribute","class",["concat",["tab-pane",["subexpr","if",[["get","by.selected",["loc",[null,[109,48],[109,59]]]]," active"],[],["loc",[null,[109,43],[109,71]]]]]]],
          ["block","ranking-buttons",[],["refaceModels",["subexpr","@mut",[["get","refaceModels",["loc",[null,[110,42],[110,54]]]]],[],[]],"reface",["subexpr","@mut",[["get","reface",["loc",[null,[110,62],[110,68]]]]],[],[]]],0,null,["loc",[null,[110,10],[110,90]]]],
          ["block","each",[["get","rankingItems",["loc",[null,[113,22],[113,34]]]]],[],1,null,["loc",[null,[113,14],[160,23]]]],
          ["block","each",[["get","rankingItems",["loc",[null,[164,22],[164,34]]]]],[],2,null,["loc",[null,[164,14],[211,23]]]]
        ],
        locals: ["by"],
        templates: [child0, child1, child2]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 277,
              "column": 6
            },
            "end": {
              "line": 283,
              "column": 6
            }
          },
          "moduleName": "soundmondo-client/templates/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","product");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'href');
          morphs[1] = dom.createAttrMorph(element3, 'src');
          morphs[2] = dom.createAttrMorph(element3, 'id');
          morphs[3] = dom.createAttrMorph(element3, 'alt');
          morphs[4] = dom.createAttrMorph(element3, 'onmouseenter');
          morphs[5] = dom.createAttrMorph(element3, 'onmouseleave');
          return morphs;
        },
        statements: [
          ["attribute","href",["get","product.url",["loc",[null,[279,21],[279,32]]]]],
          ["attribute","src",["get","product.image",["loc",[null,[280,24],[280,37]]]]],
          ["attribute","id",["concat",["productimage-",["get","product.name",["loc",[null,[280,61],[280,73]]]]]]],
          ["attribute","alt",["get","product.name",["loc",[null,[280,85],[280,97]]]]],
          ["attribute","onmouseenter",["subexpr","action",["mouseOnProduct"],[],["loc",[null,[280,114],[280,143]]]]],
          ["attribute","onmouseleave",["subexpr","action",["mouseOffProduct"],[],["loc",[null,[280,157],[280,185]]]]]
        ],
        locals: ["product"],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 285,
              "column": 6
            },
            "end": {
              "line": 291,
              "column": 6
            }
          },
          "moduleName": "soundmondo-client/templates/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","product");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'href');
          morphs[1] = dom.createAttrMorph(element1, 'src');
          morphs[2] = dom.createAttrMorph(element1, 'id');
          morphs[3] = dom.createAttrMorph(element1, 'alt');
          morphs[4] = dom.createAttrMorph(element1, 'onmouseenter');
          morphs[5] = dom.createAttrMorph(element1, 'onmouseleave');
          return morphs;
        },
        statements: [
          ["attribute","href",["get","product.url",["loc",[null,[287,21],[287,32]]]]],
          ["attribute","src",["get","product.image",["loc",[null,[288,24],[288,37]]]]],
          ["attribute","id",["concat",["productimage-",["get","product.name",["loc",[null,[288,61],[288,73]]]]]]],
          ["attribute","alt",["get","product.name",["loc",[null,[288,85],[288,97]]]]],
          ["attribute","onmouseenter",["subexpr","action",["mouseOnProduct"],[],["loc",[null,[288,114],[288,143]]]]],
          ["attribute","onmouseleave",["subexpr","action",["mouseOffProduct"],[],["loc",[null,[288,157],[288,185]]]]]
        ],
        locals: ["product"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 315,
            "column": 0
          }
        },
        "moduleName": "soundmondo-client/templates/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","ymContainer");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","myCarousel");
        dom.setAttribute(el3,"class","carousel slide");
        dom.setAttribute(el3,"data-ride","carousel");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" Indicators ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ol");
        dom.setAttribute(el4,"class","carousel-indicators");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","0");
        dom.setAttribute(el5,"class","active");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","1");
        dom.setAttribute(el5,"class","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","2");
        dom.setAttribute(el5,"class","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","3");
        dom.setAttribute(el5,"class","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","4");
        dom.setAttribute(el5,"class","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-target","#myCarousel");
        dom.setAttribute(el5,"data-slide-to","5");
        dom.setAttribute(el5,"class","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","carousel-inner");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item active");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_montagem.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_modxplus.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_refaces.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_cp.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_yc.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","item");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/top_ck88.png");
        dom.setAttribute(el6,"style","width: 100%");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","carousel-caption");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h1");
        var el8 = dom.createTextNode("Social Sound Sharing");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","play-video btn btn-lg");
        dom.setAttribute(el7,"data-toggle","modal");
        dom.setAttribute(el7,"data-target","#sampleModal");
        var el8 = dom.createTextNode("\n            	Play Video\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","left carousel-control");
        dom.setAttribute(el4,"href","#myCarousel");
        dom.setAttribute(el4,"data-slide","prev");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","glyphicon glyphicon-chevron-left");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","right carousel-control");
        dom.setAttribute(el4,"href","#myCarousel");
        dom.setAttribute(el4,"data-slide","next");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","glyphicon glyphicon-chevron-right");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" Modal ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal fade");
        dom.setAttribute(el1,"id","sampleModal");
        dom.setAttribute(el1,"tabindex","-1");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","modal-dialog");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","modal-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","modal-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("How Soundmondo works");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("iframe");
        dom.setAttribute(el5,"width","565");
        dom.setAttribute(el5,"height","315");
        dom.setAttribute(el5,"src","https://www.youtube.com/embed/Zp_LI1UiCxo");
        dom.setAttribute(el5,"frameborder","0");
        dom.setAttribute(el5,"allow","autoplay; encrypted-media");
        dom.setAttribute(el5,"allowfullscreen","");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End Banner ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","features");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container-fuild");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","popular-t");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" start ranking ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","ranking");
        dom.setAttribute(el1,"class","popular-block");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container-fuild");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","popular-t");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode(" of ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tab-content raking-content");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" end ranking ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dark-belt-t");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-md-6  col-sm-6");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/connect-reface-pc.jpg");
        dom.setAttribute(el6,"class","img-responsive");
        dom.setAttribute(el6,"alt","");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-md-6 col-sm-6");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/connect-reface-ipad.jpg");
        dom.setAttribute(el6,"class","img-responsive");
        dom.setAttribute(el6,"alt","");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End dark-belt ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" start app-store-block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","app-store-t");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-md-5 col-md-offset-2");
        var el6 = dom.createTextNode("\n          Soundmond app lets you store, recall reface and MONTAGE Voices\n          on iOS and share them on Soundmondo. Each stored Voice can be rated, named\n          and given a custom image from your photo library.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-md-3");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","app-store-i");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","https://appsto.re/us/wYMT9.i");
        dom.setAttribute(el7,"target","__blank");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/app-store.svg");
        dom.setAttribute(el8,"alt","App Store");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" end app-store-block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        dom.setAttribute(el1,"async","");
        dom.setAttribute(el1,"src","https://cdn.hypemarks.com/pages/a5b5e5.js");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tintup");
        dom.setAttribute(el1,"data-id","reface");
        dom.setAttribute(el1,"data-columns","");
        dom.setAttribute(el1,"data-mobilescroll","true");
        dom.setAttribute(el1,"data-infinitescroll","true");
        dom.setAttribute(el1,"data-personalization-id","504029");
        dom.setAttribute(el1,"style","height:300px;width:100%;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" product link ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","product-link");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","popular-t");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","products-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","products");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"id","ymhProductTooltip");
        dom.setAttribute(el4,"class","product-tooltip");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" product link ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  // adjust the tint container to generated instagram size\n  const target = document.querySelector('.tintup');\n  const observer = new MutationObserver(() => {\n    if (target.firstElementChild.style.height != '100%') {\n      target.style.height = target.firstElementChild.style.height;\n    }\n  });\n\n  const options = {\n    attributes: true,\n    subtree: true\n  };\n\n  observer.observe(target, options);\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element21 = dom.childAt(fragment, [1]);
        var element22 = dom.childAt(element21, [1, 1, 5]);
        var element23 = dom.childAt(element22, [1, 1]);
        var element24 = dom.childAt(element22, [3, 1]);
        var element25 = dom.childAt(element22, [5, 1]);
        var element26 = dom.childAt(element22, [7, 1]);
        var element27 = dom.childAt(element22, [9, 1]);
        var element28 = dom.childAt(element22, [11, 1]);
        var element29 = dom.childAt(fragment, [9, 1]);
        var element30 = dom.childAt(fragment, [13, 1]);
        var element31 = dom.childAt(element30, [1, 1]);
        var element32 = dom.childAt(fragment, [17]);
        var element33 = dom.childAt(fragment, [23]);
        var element34 = dom.childAt(fragment, [32]);
        var element35 = dom.childAt(element34, [3, 1]);
        var element36 = dom.childAt(element35, [1]);
        var morphs = new Array(22);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element21, 'class');
        morphs[2] = dom.createAttrMorph(element23, 'alt');
        morphs[3] = dom.createAttrMorph(element24, 'alt');
        morphs[4] = dom.createAttrMorph(element25, 'alt');
        morphs[5] = dom.createAttrMorph(element26, 'alt');
        morphs[6] = dom.createAttrMorph(element27, 'alt');
        morphs[7] = dom.createAttrMorph(element28, 'alt');
        morphs[8] = dom.createMorphAt(dom.childAt(element29, [1, 1]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element29, [3]),1,1);
        morphs[10] = dom.createMorphAt(element31,0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element31, [1]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element30, [4]),1,1);
        morphs[13] = dom.createAttrMorph(element32, 'class');
        morphs[14] = dom.createMorphAt(dom.childAt(element32, [1, 1, 1]),1,1);
        morphs[15] = dom.createAttrMorph(element33, 'class');
        morphs[16] = dom.createMorphAt(dom.childAt(element34, [1, 1]),0,0);
        morphs[17] = dom.createAttrMorph(element36, 'onmouseenter');
        morphs[18] = dom.createAttrMorph(element36, 'onmouseleave');
        morphs[19] = dom.createMorphAt(element35,3,3);
        morphs[20] = dom.createMorphAt(element35,7,7);
        morphs[21] = dom.createMorphAt(fragment,36,36,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","isAuthenticated",["loc",[null,[1,6],[1,21]]]]],[],0,null,["loc",[null,[1,0],[4,7]]]],
        ["attribute","class",["concat",["full-width-banner ",["subexpr","if",[["get","isAuthenticated",["loc",[null,[5,35],[5,50]]]],"hide-section"],[],["loc",[null,[5,30],[5,67]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.montagem.title"],[],["loc",[null,[20,77],[20,120]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.modxplus.title"],[],["loc",[null,[23,77],[23,120]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.refaces.title"],[],["loc",[null,[26,76],[26,118]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.cp.title"],[],["loc",[null,[29,71],[29,108]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.yc.title"],[],["loc",[null,[32,71],[32,108]]]]]]],
        ["attribute","alt",["concat",[["subexpr","t",["index.carousel.slide.ck88.title"],[],["loc",[null,[35,73],[35,112]]]]]]],
        ["inline","t",["index.features.title"],[],["loc",[null,[66,29],[66,59]]]],
        ["block","each",[["get","featuredUser.allFeatures",["loc",[null,[70,14],[70,38]]]]],[],1,null,["loc",[null,[70,6],[85,15]]]],
        ["inline","t",["index.ranking.title"],[],["loc",[null,[94,29],[94,56]]]],
        ["content","rankingService.lastMonth",["loc",[null,[94,66],[94,94]]]],
        ["block","each",[["get","rankingBys",["loc",[null,[108,14],[108,24]]]]],[],2,null,["loc",[null,[108,6],[216,15]]]],
        ["attribute","class",["concat",["dark-belt ",["subexpr","if",[["get","isAuthenticated",["loc",[null,[222,27],[222,42]]]],"hide-section"],[],["loc",[null,[222,22],[222,59]]]]]]],
        ["inline","t",["index.connect.title"],[],["loc",[null,[226,8],[226,35]]]],
        ["attribute","class",["concat",["app-store ",["subexpr","if",[["get","isAuthenticated",["loc",[null,[246,27],[246,42]]]],"hide-section"],[],["loc",[null,[246,22],[246,59]]]]]]],
        ["inline","t",["index.products.title"],[],["loc",[null,[272,27],[272,57]]]],
        ["attribute","onmouseenter",["subexpr","action",["mouseOnTooltip"],[],["loc",[null,[276,71],[276,100]]]]],
        ["attribute","onmouseleave",["subexpr","action",["mouseOffTooltip"],[],["loc",[null,[276,114],[276,144]]]]],
        ["block","each",[["get","prodctListsUpper",["loc",[null,[277,14],[277,30]]]]],[],3,null,["loc",[null,[277,6],[283,15]]]],
        ["block","each",[["get","prodctListsLower",["loc",[null,[285,14],[285,30]]]]],[],4,null,["loc",[null,[285,6],[291,15]]]],
        ["content","outlet",["loc",[null,[297,0],[297,10]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});