define('soundmondo-client/models/set-membership', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    set: DS['default'].belongsTo('set', { async: true, inverse: null }),
    voice: DS['default'].belongsTo('voice', { async: true, inverse: null }),
    created: DS['default'].attr('date'),
    updated: DS['default'].attr('date')
  });

});