define('soundmondo-client/routes/voices/create', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, AuthenticatedRouteMixin, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], ResetScrollMixin['default'], {
    model: function model() {
      return {
        name: '',
        description: '',
        'private': true,
        _private: true,
        social: {}
      };
    },

    resetController: function resetController(controller) {
      controller.set('incompatibleDataFormat', undefined);
    },

    actions: {
      didTransition: function didTransition() {
        this.controller.clearErrors();
        this.controller.setTitle();
      },

      willTransition: function willTransition() {
        window.document.body.style.cursor = 'default';
      }
    }
  });

});