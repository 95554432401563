define('soundmondo-client/serializers/voice', ['exports', 'ember', 'soundmondo-client/serializers/drf'], function (exports, Ember, DRFSerializer) {

  'use strict';

  exports['default'] = DRFSerializer['default'].extend({
    normalize: function normalize(typeClass, hash) {
      hash['voice'] = hash['data'];
      hash['_private'] = hash['private'];

      delete hash['data'];
      delete hash['private'];

      return this._super.apply(this, arguments);
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      var belongsTo = snapshot.belongsTo(key);

      key = this.keyForRelationship ? this.keyForRelationship(key, "belongsTo", "serialize") : key;

      var belongsToAdapter = this.store.adapterFor(key);

      json[key] = Ember['default'].isNone(belongsTo) ? belongsTo : belongsToAdapter.buildURL(key, belongsTo.id);
    },

    serialize: function serialize() {
      var payload = this._super.apply(this, arguments);
      payload['data'] = payload['voice'];
      payload['private'] = payload['_private'];

      delete payload['voice'];
      delete payload['_private'];

      return payload;
    },

    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      if (!Ember['default'].isNone(meta)) {
        // Add totalPages to metadata.
        var totalPages = 1;
        if (!Ember['default'].isNone(meta.next)) {
          // Any page that is not the last page.
          totalPages = Math.ceil(meta.count / payload.length);
        } else if (Ember['default'].isNone(meta.next) && !Ember['default'].isNone(meta.previous)) {
          // The last page when there is more than one page.
          totalPages = meta.previous + 1;
        }
        meta['totalPages'] = totalPages;
      }
      return meta;
    }
  });

});