define('soundmondo-client/initializers/simple-auth-cookie-store', ['exports', 'simple-auth-cookie-store/configuration', 'simple-auth-cookie-store/stores/cookie', 'soundmondo-client/config/environment'], function (exports, Configuration, Store, ENV) {

  'use strict';

  exports['default'] = {
    name: 'simple-auth-cookie-store',
    before: 'simple-auth',
    initialize: function initialize(container, application) {
      Configuration['default'].load(container, ENV['default']['simple-auth-cookie-store'] || {});
      container.register('simple-auth-session-store:cookie', Store['default']);
    }
  };

});