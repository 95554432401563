define('soundmondo-client/routes/voices/edit', ['exports', 'ember', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ResetScrollMixin['default'], {
    devices: Ember['default'].inject.service('devices'),
    model: function model(params) {
      return this.get('store').find('voice', params.voice_id);
    },

    setupController: function setupController(controller, model) {
      this.controllerFor('voices.create').set('model', model);
    },

    activate: function activate() {
      this.controllerFor('voices.create').set('syncMode', true);
      this.controllerFor('voices.create').set('editMode', true);
    },

    renderTemplate: function renderTemplate() {
      var controller = this.controllerFor('voices.edit'),
          sysex = controller.get('model.voice.sysex');
      this.render('voices/create', {
        'controller': 'voices/create'
      });
    }
  });

});