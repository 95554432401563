define('soundmondo-client/controllers/user', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service('session'),
    isAuthenticated: Ember['default'].computed.alias('session.isAuthenticated'),
    user: Ember['default'].inject.service(),
    canEdit: Ember['default'].computed.and('isAuthenticated', 'isUser'),
    isEditing: false,
    editName: false,
    editBio: false,
    editSocial: false,
    showSoundshares: true,
    showFavorites: false,
    showTrash: false,
    currentSet: null,
    addingSetList: false,
    toggleSetLists: false,
    setName: null,
    draggingFavorite: false,
    draggingSetItem: false,
    soundsSortByName: false,
    soundsSortByDate: true,
    soundsSortByReface: false,
    favoritesSortByName: false,
    favoritesSortByDate: true,
    favoritesSortByReface: false,
    trashSortByName: false,
    trashSortByDate: true,
    trashSortByReface: false,
    showAddSetList: Ember['default'].computed('canEdit', 'addingSetList', function () {
      return this.get('canEdit') ? !this.get('addingSetList') : false;
    }),
    errors: [],
    isUser: Ember['default'].computed('session.secure.id', 'model.user.id', function () {
      var sessionUserId = this.get('session.secure.id'),
          userId = this.get('model.user.id');
      return userId == sessionUserId; // jshint ignore:line
    }),
    deletedVoicesCount: null,
    isSingleDeletion: Ember['default'].computed(function () {
      return this.get('deletedVoicesCount') === 1;
    }),
    infoMessage: null,

    voices: Ember['default'].computed('isUser', 'model.user.id', 'soundsSortByName', 'soundsSortByReface', 'soundsSortByDate', function () {
      var userId = this.get('model.user.id'),
          soundsSortByName = this.get('soundsSortByName'),
          soundsSortByDate = this.get('soundsSortByDate'),
          soundsSortByReface = this.get('soundsSortByReface'),
          ordering = [],
          orderingQs = '',
          isUser = this.get('isUser'),
          isPrivate = 'False';

      if (isUser) {
        isPrivate = '';
      }

      if (soundsSortByName) {
        ordering.push('name');
      }

      if (soundsSortByDate) {
        ordering.push('created');
      }

      if (soundsSortByReface) {
        ordering.push('reface');
      }

      if (ordering.length > 0) {
        orderingQs = ordering.join(',');
      }

      return this.get('store').findQuery('voice', { user: userId,
        page_size: 200,
        trashed: 'False',
        'private': isPrivate,
        ordering: orderingQs });
    }),

    favorites: Ember['default'].computed('model.user.id', 'favoritesSortByName', 'favoritesSortByReface', 'favoritesSortByDate', function () {
      var userId = this.get('model.user.id'),
          favoritesSortByName = this.get('favoritesSortByName'),
          favoritesSortByDate = this.get('favoritesSortByDate'),
          favoritesSortByReface = this.get('favoritesSortByReface'),
          ordering = [],
          orderingQs = '';

      if (favoritesSortByName) {
        ordering.push('name');
      }

      if (favoritesSortByDate) {
        ordering.push('created');
      }

      if (favoritesSortByReface) {
        ordering.push('reface');
      }

      if (ordering.length > 0) {
        orderingQs = ordering.join(',');
      }

      return this.get('store').findQuery('voice', { favorited_by: userId,
        page_size: 200,
        ordering: orderingQs });
    }),

    trashedVoices: Ember['default'].computed('model.user.id', 'trashSortByName', 'trashSortByReface', 'trashSortByDate', function () {
      var userId = this.get('model.user.id'),
          trashSortByName = this.get('trashSortByName'),
          trashSortByDate = this.get('trashSortByDate'),
          trashSortByReface = this.get('trashSortByReface'),
          ordering = [],
          orderingQs = '';

      if (trashSortByName) {
        ordering.push('name');
      }

      if (trashSortByDate) {
        ordering.push('created');
      }

      if (trashSortByReface) {
        ordering.push('reface');
      }

      if (ordering.length > 0) {
        orderingQs = ordering.join(',');
      }

      return this.get('store').findQuery('voice', { user: userId,
        page_size: 200,
        trashed: 'True',
        ordering: orderingQs });
    }),

    clearEdit: function clearEdit() {
      this.setProperties({
        isEditing: false
      });
    },

    clearErrors: function clearErrors() {
      this.set('errors', []);
    },

    clearInfo: function clearInfo() {
      this.set('infoMessage', null);
      this.set('deletedVoicesCount', null);
    },

    actions: {
      soundsOrderByName: function soundsOrderByName() {
        this.toggleProperty('soundsSortByName');
      },

      soundsOrderByDate: function soundsOrderByDate() {
        this.toggleProperty('soundsSortByDate');
      },

      soundsOrderByReface: function soundsOrderByReface() {
        this.toggleProperty('soundsSortByReface');
      },

      favoritesOrderByName: function favoritesOrderByName() {
        this.toggleProperty('favoritesSortByName');
      },

      favoritesOrderByDate: function favoritesOrderByDate() {
        this.toggleProperty('favoritesSortByDate');
      },

      favoritesOrderByReface: function favoritesOrderByReface() {
        this.toggleProperty('favoritesSortByReface');
      },

      trashOrderByName: function trashOrderByName() {
        this.toggleProperty('trashSortByName');
      },

      trashOrderByDate: function trashOrderByDate() {
        this.toggleProperty('trashSortByDate');
      },

      trashOrderByReface: function trashOrderByReface() {
        this.toggleProperty('trashSortByReface');
      },

      isDragFavorite: function isDragFavorite() {
        this.set('draggingFavorite', true);
      },

      stopDragFavorite: function stopDragFavorite() {
        this.set('draggingFavorite', false);
      },

      isDragSetItem: function isDragSetItem() {
        this.set('draggingSetItem', true);
      },

      stopDragSetItem: function stopDragSetItem() {
        this.set('draggingSetItem', false);
      },

      startEdit: function startEdit() {
        this.set('isEditing', true);
      },

      cancelEdit: function cancelEdit() {
        this.set('isEditing', false);
        this.get('model.user').rollback();
        this.get('model.user').reload();
      },

      saveUser: function saveUser() {
        var _this = this,
            displayName = this.get('model.user.displayName');

        this.clearErrors();
        this.clearInfo();

        if (Ember['default'].isEmpty(displayName)) {
          this.set('errors', [{ detail: 'Please fill in your name.' }]);
          return;
        }

        if (displayName.length > 80) {
          this.set('errors', [{ detail: 'Display name must be 80 characters or less.' }]);
          return;
        }

        this.get('model.user').save().then(function (user) {
          _this.set('model.user', user);

          _this.clearEdit();
        })['catch'](function (response) {
          if (response.errors) {
            _this.set('errors', response.errors);
          } else {
            _this.set('errors', [{ detail: 'Save failed.' }]);
          }
        });
      },

      showSoundshares: function showSoundshares() {
        this.setProperties({ showSoundshares: true,
          showFavorites: false,
          showTrash: false,
          currentSet: null });
      },

      showFavorites: function showFavorites() {
        this.setProperties({ showSoundshares: false,
          showFavorites: true,
          showTrash: false,
          currentSet: null });
      },

      showTrash: function showTrash() {
        this.setProperties({ showSoundshares: false,
          showFavorites: false,
          showTrash: true,
          currentSet: null });
      },

      showSet: function showSet(set) {
        this.setProperties({ showSoundshares: false,
          showFavorites: false,
          showTrash: false,
          currentSet: set });
      },

      addSetList: function addSetList() {
        this.set('addingSetList', true);
      },

      doneAddingSetList: function doneAddingSetList() {
        this.set('addingSetList', false);
      },

      createSet: function createSet() {
        var _this = this,
            store = this.get('store'),
            setName = this.get('setName'),
            user = this.get('model.user');
        if (setName) {
          store.createRecord('set', {
            name: setName,
            user: user
          }).save().then(function () {
            _this._actions.doneAddingSetList.call(_this);
          })['catch'](function (response) {
            console.log(response);
          });
        }
      },

      toggleSetLists: function toggleSetLists() {
        this.toggleProperty('toggleSetLists');
      },

      sendToTrash: function sendToTrash(voice, options) {
        var _this = this,
            token = this.get('session.secure.token'),
            user = this.get('model.user'),
            url = ENV['default'].APP.API_ENDPOINT + '/voices/' + voice.id + '/favorite/',
            isTrashed = voice.get('trashed'),
            isFavorite = options.target.get('isFavorite') || false,
            isDraggingSetItem = this.get('draggingSetItem') || false,
            currentSet = this.get('currentSet'),
            store = this.store;

        if (isDraggingSetItem && currentSet) {
          store.query('set-membership', { voice: voice.id, set: currentSet.id }).then(function (members) {
            members.forEach(function (member) {
              member.destroyRecord().then(function () {
                currentSet.get('voices').reload().then(function () {
                  _this.notifyPropertyChange('currentSet');
                  _this._actions.showSet.call(_this, currentSet);
                });
              });
            });
          });

          return;
        }

        if (isFavorite && token) {
          Ember['default'].$.ajax({
            url: url,
            method: 'DELETE',
            contentType: false,
            processData: false,
            headers: {
              'Authorization': 'Token ' + token
            }
          }).then(function () {
            _this.notifyPropertyChange('favorites');
          }, function () {});

          return;
        }

        if (!isTrashed) {
          voice.set('user', user);
          voice.set('trashed', true);
          voice.save().then(function () {
            _this.notifyPropertyChange('voices');
            _this.notifyPropertyChange('trashedVoices');
            _this._actions.showTrash.call(_this);
          });
        }
      },

      undoTrash: function undoTrash(voice) {
        var _this = this,
            isTrashed = voice.get('trashed'),
            user = this.get('model.user');
        if (isTrashed) {
          voice.set('user', user);
          voice.set('trashed', false);
          voice.save().then(function () {
            _this.notifyPropertyChange('voices');
            _this.notifyPropertyChange('trashedVoices');
            _this._actions.showSoundshares.call(_this);
          });
        }
      },

      addToSet: function addToSet(voice, options) {
        var currentSet = options.target.get('currentSet'),
            _this = this;

        if (currentSet && voice) {
          this.store.createRecord('set-membership', {
            set: currentSet,
            voice: voice
          }).save().then(function () {
            currentSet.get('voices').reload().then(function () {
              _this.notifyPropertyChange('currentSet');
              _this._actions.showSet.call(_this, currentSet);
            });
          });
        }
      },

      uploadPhoto: function uploadPhoto(files) {
        var token = this.get('session.secure.token'),
            user = this.get('model.user'),
            _this = this;

        this.set('errors', []);

        var uploadUrl = ENV['default'].APP.API_ENDPOINT + '/users/' + user.id + '/upload_photo/',
            formData = new FormData();

        formData.append('file', files[0]);

        if (token) {
          Ember['default'].$.ajax({
            url: uploadUrl,
            method: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            headers: {
              'Authorization': 'Token ' + token
            }
          }).then(function (resp) {
            _this.set('model.user.photo', resp.photo);
          }, function () {
            _this.set('errors', [{ detail: 'Sorry, failed to save the profile photo.' }]);
          });
        }
      },

      emptyTrash: function emptyTrash() {
        var token = this.get('session.secure.token'),
            adapter = this.store.adapterFor('application'),
            url = adapter.buildURL('voice') + 'trashed',
            user = this.get('model.user'),
            _this = this;

        if (!token) {
          return;
        }

        _this.clearErrors();
        _this.clearInfo();

        Ember['default'].$.ajax({
          url: url,
          method: 'DELETE',
          data: {},
          contentType: false,
          processData: false,
          headers: {
            'Authorization': 'Token ' + token
          }
        }).then(function (response) {
          var count = response.count;
          _this.set('deletedVoicesCount', count);
          if (count === 1) {
            _this.set('infoMessage', 'One voice has been deleted.');
          } else if (1 < count) {
            _this.set('infoMessage', response.count + ' voices have been deleted.');
          }
          _this.send('trashOrderByDate');
        }, function () {
          _this.set('errors', [{ detail: 'Unable to empty trash.' }]);
        });
      },

      deleteSet: function deleteSet() {
        var currentSet = this.get('currentSet'),
            _this = this;

        _this.clearErrors();
        _this.clearInfo();

        currentSet.destroyRecord().then(function () {
          _this.set('infoMessage', 'The set has been deleted.');
          _this.send('showSoundshares');
        });
      }
    }
  });

});