define('soundmondo-client/routes/voices/sync', ['exports', 'ember', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ResetScrollMixin['default'], {
    devices: Ember['default'].inject.service('devices'),
    model: function model(params) {
      return this.get('store').find('voice', params.voice_id);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    activate: function activate() {
      this.controllerFor('voices.create').set('syncMode', true);
    },

    renderTemplate: function renderTemplate() {
      var controller = this.controllerFor('voices.sync'),
          reface = controller.get('model.reface'),
          sysex = controller.get('model.voice.sysex');
      this.render('voices/create', {
        'controller': 'voices/create'
      });

      this.controllerFor('voices.create').set('model', {
        name: '',
        description: '',
        social: {},
        'private': false,
        _private: true,
        voice: {
          sysex: sysex,
          reface: reface
        }
      });
    },

    resetController: function resetController(controller) {
      this.controllerFor('voices.create').removeVersionCheckListener();
    },

    actions: {
      didTransition: function didTransition() {
        this.controllerFor('voices.create').addVersionCheckListener();
      }
    }
  });

});