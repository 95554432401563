/* jshint ignore:start */

define('soundmondo-client/config/environment', ['ember'], function(Ember) {
  var prefix = 'soundmondo-client';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (runningTests) {
  require("soundmondo-client/tests/test-helper");
} else {
  require("soundmondo-client/app")["default"].create({"API_NAMESPACE":"api/v1","SOUNDCLOUD_CLIENT_ID":"28618f4f3c873d67382a4f3d8e954050","SHOW_DEV_LOGIN_FORM":true,"API_HOST":"https://dev-backend.soundmondo.yamahasynth.com","API_ENDPOINT":"https://dev-backend.soundmondo.yamahasynth.com/api/v1","name":"soundmondo-client","version":"0.0.0+","API_ADD_TRAILING_SLASHES":true});
}

/* jshint ignore:end */
