define('soundmondo-client/controllers/voices/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(Ember['default'].SortableMixin, {
    queryParams: ['tags', 'reface', 'search', 'user'],
    tags: [],
    reface: null,
    refaceModelString: Ember['default'].computed('reface', function () {
      // Browse の Viewing で実際に表示する文字列を生成する

      // rf(reface) の値は ember meta を含む配列か素の文字列となる
      // 最初に判定して素の文字列にする
      var rf = this.get('reface');
      var modelName = "";

      if (rf == null) {
        // 'reface' が null のときは参照元でそもそも参照を回避しているが、
        // 元処理との一貫性のため null を返す
        return null;
      }

      if (Array.isArray(rf)) {
        // __ember_meta__ を含む配列
        if (rf.length > 1) {
          modelName = rf.join('/');
        } else {
          modelName = rf[0];
        }
      } else {
        // 素の文字列
        modelName = rf;
      }

      // 複数のモデルが - で連結されている場合は / に置き換える
      // 2022/09 現在モデル名に - を含むものは存在しない
      // 将来的に追加される場合は何かしらの回避策を入れること
      modelName = modelName.replaceAll(/-/g, "/");

      // MODXPLUS の置き換え
      modelName = modelName.replaceAll(/MODXPLUS/g, "MODX+");

      // データ上のモデル名と表示名を違えさせる場合の追加処理
      if (/^CP8873/.test(modelName)) {
        // CP8873 は CP73/88
        modelName = "CP73/88";
      } else if (/^YC61/.test(modelName)) {
        // YC61 は YC61/73/88
        modelName = "YC61/73/88";
      } else if (/^CK8861/.test(modelName)) {
        // CK8861 は CK61/88
        modelName = "CK61/88";
      } else if (/^(CS|DX|YC|CP)/.test(modelName)) {
        // reface は CS, DX としか表示されないので reface という文字列を漬けて reface XX という表示にする
        modelName = 'reface ' + modelName;
      } else if (/^MMONTAGEM/.test(modelName)) {
        // MONTAGE M
        modelName = "MONTAGE M";
      }

      return modelName;
    }),
    search: null,
    user: null,
    refaceModels: [{ model: 'CS', selected: false }, { model: 'DX', selected: false }, { model: 'YC', selected: false }, { model: 'CP', selected: false }, { model: 'MONTAGE-MODX-MODXplus', selected: false }, { model: 'CP8873', selected: false }, { model: 'CK8861', selected: false }, { model: 'YC61', selected: false }, { model: 'MMONTAGEM', selected: false }],
    // 'tags'の後に定義した値は正しく判定されないため、必ず最後に定義しておくこと
    isFiltered: Ember['default'].computed.or('user', 'search', 'reface', 'tags'),

    application: Ember['default'].inject.controller(),

    refaceModelRow1: Ember['default'].computed('refaceModels', function () {
      return this.get('refaceModels').slice(0, 2);
    }),

    refaceModelRow2: Ember['default'].computed('refaceModels', function () {
      return this.get('refaceModels').slice(2, 4);
    }),

    refaceModelRow3: Ember['default'].computed('refaceModels', function () {
      return this.get('refaceModels').slice(4, 6);
    }),

    refaceModelRow4: Ember['default'].computed('refaceModels', function () {
      return this.get('refaceModels').slice(6, 8);
    }),

    refaceModelRow5: Ember['default'].computed('refaceModels', function () {
      return this.get('refaceModels').slice(8, 9);
    }),

    selectedUser: Ember['default'].computed(function () {
      if (this.get('user')) {
        return this.get('store').findRecord('user', this.get('user'));
      } else {
        return null;
      }
    }),

    actions: {
      tagToggled: function tagToggled(tag) {
        if (!Ember['default'].isArray(this.get('tags'))) {
          this.set('tags', [this.get('tags')]);
        }

        if (this.get('tags').contains(tag.get('name'))) {
          this.get('tags').removeObject(tag.get('name'));
          tag.set('selected', false);
        } else {
          this.get('tags').pushObject(tag.get('name'));
          tag.set('selected', true);
        }
      },

      toggleReface: function toggleReface(rf) {
        if (rf.selected) {
          Ember['default'].set(rf, 'selected', false);
          this.set('reface', null);
        } else {
          this.get('refaceModels').setEach('selected', false);
          Ember['default'].set(rf, 'selected', true);
          this.set('reface', [rf.model]);
        }
      },

      clearUser: function clearUser() {
        if (this.get('user')) {
          this.set('user', null);
        }
      },

      clearFilters: function clearFilters() {
        this.propertyWillChange('tags');
        this.setProperties({
          tags: [],
          search: null,
          reface: null,
          user: null,
          refaceModels: [{ model: 'CS', selected: false }, { model: 'DX', selected: false }, { model: 'YC', selected: false }, { model: 'CP', selected: false }, { model: 'MONTAGE-MODX-MODXplus', selected: false }, { model: 'CP8873', selected: false }, { model: 'CK8861', selected: false }, { model: 'YC61', selected: false }]
        });
        this.get('application').send("clearSearchWord");

        this.propertyDidChange('tags');
      }
    }
  });

});