define('soundmondo-client/templates/voices/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 14
              },
              "end": {
                "line": 12,
                "column": 14
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"alt","profile");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element27 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element27, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","selectedUser.photo",["loc",[null,[11,26],[11,44]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 14
              },
              "end": {
                "line": 14,
                "column": 14
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"alt","profile");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element26, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","selectedUser.gravatar",["loc",[null,[13,26],[13,47]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 12
              },
              "end": {
                "line": 18,
                "column": 69
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("View Profile");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 12
              },
              "end": {
                "line": 30,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1, 1]);
            var element25 = dom.childAt(element24, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element24, 'class');
            morphs[1] = dom.createElementMorph(element24);
            morphs[2] = dom.createAttrMorph(element25, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[26,29],[26,40]]]],"reface-selected"],[],["loc",[null,[26,24],[26,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[26,100],[26,102]]]]],["on","click"],["loc",[null,[26,76],[26,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[27,48],[27,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 12
              },
              "end": {
                "line": 39,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1, 1]);
            var element23 = dom.childAt(element22, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element22, 'class');
            morphs[1] = dom.createElementMorph(element22);
            morphs[2] = dom.createAttrMorph(element23, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[35,29],[35,40]]]],"reface-selected"],[],["loc",[null,[35,24],[35,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[35,100],[35,102]]]]],["on","click"],["loc",[null,[35,76],[35,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[36,48],[36,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 12
              },
              "end": {
                "line": 48,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1, 1]);
            var element21 = dom.childAt(element20, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element20, 'class');
            morphs[1] = dom.createElementMorph(element20);
            morphs[2] = dom.createAttrMorph(element21, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[44,29],[44,40]]]],"reface-selected"],[],["loc",[null,[44,24],[44,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[44,100],[44,102]]]]],["on","click"],["loc",[null,[44,76],[44,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[45,48],[45,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 12
              },
              "end": {
                "line": 57,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1, 1]);
            var element19 = dom.childAt(element18, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element18, 'class');
            morphs[1] = dom.createElementMorph(element18);
            morphs[2] = dom.createAttrMorph(element19, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[53,29],[53,40]]]],"reface-selected"],[],["loc",[null,[53,24],[53,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[53,100],[53,102]]]]],["on","click"],["loc",[null,[53,76],[53,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[54,48],[54,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 12
              },
              "end": {
                "line": 66,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1, 1]);
            var element17 = dom.childAt(element16, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element16, 'class');
            morphs[1] = dom.createElementMorph(element16);
            morphs[2] = dom.createAttrMorph(element17, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[62,29],[62,40]]]],"reface-selected"],[],["loc",[null,[62,24],[62,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[62,100],[62,102]]]]],["on","click"],["loc",[null,[62,76],[62,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[63,48],[63,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 81,
              "column": 4
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row m-t-20 m-b-20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-3 devider-r");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","profile");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","profile-icon");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-3 devider-r");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createTextNode("Browse by Instrument");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-6");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createTextNode("Browse by Tag");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","search-inst-tag-with-user");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tags");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [1]);
          var element29 = dom.childAt(element28, [1]);
          var element30 = dom.childAt(element29, [3, 1]);
          var element31 = dom.childAt(element28, [3]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
          morphs[1] = dom.createElementMorph(element30);
          morphs[2] = dom.createMorphAt(element30,1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element29, [5]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element31, [3]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element31, [5]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element31, [7]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element31, [9]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element31, [11]),1,1);
          morphs[9] = dom.createMorphAt(dom.childAt(element28, [5, 3, 1]),1,1);
          return morphs;
        },
        statements: [
          ["content","selectedUser.fullName",["loc",[null,[7,34],[7,59]]]],
          ["element","action",["clearUser"],["on","click"],["loc",[null,[9,36],[9,69]]]],
          ["block","if",[["get","selectedUser.photo",["loc",[null,[10,20],[10,38]]]]],[],0,1,["loc",[null,[10,14],[14,21]]]],
          ["block","link-to",["user",["get","user",["loc",[null,[18,30],[18,34]]]]],["class","btn btn-info"],2,null,["loc",[null,[18,12],[18,81]]]],
          ["block","each",[["get","refaceModelRow1",["loc",[null,[24,20],[24,35]]]]],[],3,null,["loc",[null,[24,12],[30,21]]]],
          ["block","each",[["get","refaceModelRow2",["loc",[null,[33,20],[33,35]]]]],[],4,null,["loc",[null,[33,12],[39,21]]]],
          ["block","each",[["get","refaceModelRow3",["loc",[null,[42,20],[42,35]]]]],[],5,null,["loc",[null,[42,12],[48,21]]]],
          ["block","each",[["get","refaceModelRow4",["loc",[null,[51,20],[51,35]]]]],[],6,null,["loc",[null,[51,12],[57,21]]]],
          ["block","each",[["get","refaceModelRow5",["loc",[null,[60,20],[60,35]]]]],[],7,null,["loc",[null,[60,12],[66,21]]]],
          ["inline","tag-box",[],["action","tagToggled","selected",["subexpr","@mut",[["get","tags",["loc",[null,[75,53],[75,57]]]]],[],[]]],["loc",[null,[75,14],[75,59]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 12
              },
              "end": {
                "line": 92,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1]);
            var element13 = dom.childAt(element12, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element12);
            morphs[2] = dom.createAttrMorph(element13, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[88,29],[88,40]]]],"reface-selected"],[],["loc",[null,[88,24],[88,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[88,100],[88,102]]]]],["on","click"],["loc",[null,[88,76],[88,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[89,48],[89,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 12
              },
              "end": {
                "line": 101,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1, 1]);
            var element11 = dom.childAt(element10, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            morphs[1] = dom.createElementMorph(element10);
            morphs[2] = dom.createAttrMorph(element11, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[97,29],[97,40]]]],"reface-selected"],[],["loc",[null,[97,24],[97,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[97,100],[97,102]]]]],["on","click"],["loc",[null,[97,76],[97,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[98,48],[98,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 12
              },
              "end": {
                "line": 110,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 1]);
            var element9 = dom.childAt(element8, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createAttrMorph(element9, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[106,29],[106,40]]]],"reface-selected"],[],["loc",[null,[106,24],[106,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[106,100],[106,102]]]]],["on","click"],["loc",[null,[106,76],[106,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[107,48],[107,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 12
              },
              "end": {
                "line": 119,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1, 1]);
            var element7 = dom.childAt(element6, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createElementMorph(element6);
            morphs[2] = dom.createAttrMorph(element7, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[115,29],[115,40]]]],"reface-selected"],[],["loc",[null,[115,24],[115,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[115,100],[115,102]]]]],["on","click"],["loc",[null,[115,76],[115,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[116,48],[116,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 122,
                "column": 12
              },
              "end": {
                "line": 128,
                "column": 12
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-6 col-sm-6 col-xs-6 text-center search-img");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"alt","");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(element4, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createAttrMorph(element5, 'src');
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[["subexpr","if",[["get","rf.selected",["loc",[null,[124,29],[124,40]]]],"reface-selected"],[],["loc",[null,[124,24],[124,60]]]]," reface-option"]]],
            ["element","action",["toggleReface",["get","rf",["loc",[null,[124,100],[124,102]]]]],["on","click"],["loc",[null,[124,76],[124,115]]]],
            ["attribute","src",["concat",["assets/images/inst-",["get","rf.model",["loc",[null,[125,48],[125,56]]]],".png"]]]
          ],
          locals: ["rf"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 4
            },
            "end": {
              "line": 143,
              "column": 4
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row m-t-20 m-b-20");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4 devider-r");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createTextNode("Browse by Instrument");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-8");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","search-title");
          var el4 = dom.createTextNode("Browse by Tag");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","search-inst-tag");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tags");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1]);
          var element15 = dom.childAt(element14, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element15, [7]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element15, [9]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element15, [11]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element14, [3, 3, 1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","refaceModelRow1",["loc",[null,[86,20],[86,35]]]]],[],0,null,["loc",[null,[86,12],[92,21]]]],
          ["block","each",[["get","refaceModelRow2",["loc",[null,[95,20],[95,35]]]]],[],1,null,["loc",[null,[95,12],[101,21]]]],
          ["block","each",[["get","refaceModelRow3",["loc",[null,[104,20],[104,35]]]]],[],2,null,["loc",[null,[104,12],[110,21]]]],
          ["block","each",[["get","refaceModelRow4",["loc",[null,[113,20],[113,35]]]]],[],3,null,["loc",[null,[113,12],[119,21]]]],
          ["block","each",[["get","refaceModelRow5",["loc",[null,[122,20],[122,35]]]]],[],4,null,["loc",[null,[122,12],[128,21]]]],
          ["inline","tag-box",[],["action","tagToggled","selected",["subexpr","@mut",[["get","tags",["loc",[null,[137,53],[137,57]]]]],[],[]]],["loc",[null,[137,14],[137,59]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 157,
                "column": 12
              },
              "end": {
                "line": 157,
                "column": 49
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","refaceModelString",["loc",[null,[157,26],[157,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 12
              },
              "end": {
                "line": 158,
                "column": 38
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","tags",["loc",[null,[158,30],[158,38]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 45
              },
              "end": {
                "line": 158,
                "column": 84
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" -- matching \"");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\"");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","search",["loc",[null,[158,73],[158,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 156,
              "column": 10
            },
            "end": {
              "line": 159,
              "column": 10
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","reface",["loc",[null,[157,18],[157,24]]]]],[],0,null,["loc",[null,[157,12],[157,56]]]],
          ["block","if",[["get","tags",["loc",[null,[158,18],[158,22]]]]],[],1,null,["loc",[null,[158,12],[158,45]]]],
          ["block","if",[["get","search",["loc",[null,[158,51],[158,57]]]]],[],2,null,["loc",[null,[158,45],[158,91]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 10
            },
            "end": {
              "line": 161,
              "column": 10
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            All\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 10
            },
            "end": {
              "line": 163,
              "column": 52
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" by ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","selectedUser.fullName",["loc",[null,[163,26],[163,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 164,
              "column": 10
            },
            "end": {
              "line": 164,
              "column": 126
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"style","padding-left: 10px;font-size:75%");
          dom.setAttribute(el1,"href","#");
          var el2 = dom.createTextNode("clear");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["clearFilters"],["on","click"],["loc",[null,[164,80],[164,116]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 174,
                "column": 28
              },
              "end": {
                "line": 174,
                "column": 104
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","voice.name",["loc",[null,[174,90],[174,104]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 175,
                "column": 28
              },
              "end": {
                "line": 175,
                "column": 137
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","voice.userFullName",["loc",[null,[175,115],[175,137]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 178,
                "column": 10
              },
              "end": {
                "line": 178,
                "column": 87
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","voice.snapshotImage",["loc",[null,[178,57],[178,76]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 182,
                  "column": 8
                },
                "end": {
                  "line": 182,
                  "column": 97
                }
              },
              "moduleName": "soundmondo-client/templates/voices/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","tag",["loc",[null,[182,90],[182,97]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 8
              },
              "end": {
                "line": 183,
                "column": 8
              }
            },
            "moduleName": "soundmondo-client/templates/voices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["voices.index",["subexpr","query-params",[],["tags",["get","tag",["loc",[null,[182,53],[182,58]]]]],["loc",[null,[182,34],[182,59]]]]],["classNames","artist-t-link-2"],0,null,["loc",[null,[182,8],[182,109]]]]
          ],
          locals: ["tag"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 4
            },
            "end": {
              "line": 187,
              "column": 4
            }
          },
          "moduleName": "soundmondo-client/templates/voices/index.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-md-6 col-sm-6 artist-mar");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","artist-t");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("   \n        by   ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","responsmg");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(element2,0,0);
          morphs[2] = dom.createMorphAt(element2,2,2);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
          morphs[5] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","open-new-row",[["get","index",["loc",[null,[172,19],[172,24]]]]],[],["loc",[null,[172,4],[172,26]]]],
          ["block","link-to",["voices.view",["get","voice.id",["loc",[null,[174,53],[174,61]]]]],["classNames","artist-t-link"],0,null,["loc",[null,[174,28],[174,116]]]],
          ["block","link-to",["voices.index",["subexpr","query-params",[],["user",["get","voice.userId",["loc",[null,[175,73],[175,85]]]]],["loc",[null,[175,54],[175,86]]]]],["classNames","artist-t-link"],1,null,["loc",[null,[175,28],[175,149]]]],
          ["block","link-to",["voices.view",["get","voice.id",["loc",[null,[178,35],[178,43]]]]],[],2,null,["loc",[null,[178,10],[178,99]]]],
          ["block","each",[["get","voice.tags",["loc",[null,[181,16],[181,26]]]]],[],3,null,["loc",[null,[181,8],[183,17]]]],
          ["inline","close-new-row",[["get","index",["loc",[null,[186,20],[186,25]]]]],[],["loc",[null,[186,4],[186,27]]]]
        ],
        locals: ["voice","index"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 192,
            "column": 0
          }
        },
        "moduleName": "soundmondo-client/templates/voices/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" start content area block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","search-bg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End content area block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End Viewing CS block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","content-area");
        dom.setAttribute(el1,"style","padding-bottom: 300px");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-md-10 col-sm-10 ");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","viewingcs");
        var el6 = dom.createTextNode("\n          Viewing\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          Sounds\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","spacer150");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End Viewing CS block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element32 = dom.childAt(fragment, [8, 1]);
        var element33 = dom.childAt(element32, [1, 1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),1,1);
        morphs[1] = dom.createMorphAt(element33,1,1);
        morphs[2] = dom.createMorphAt(element33,3,3);
        morphs[3] = dom.createMorphAt(element33,5,5);
        morphs[4] = dom.createMorphAt(element32,5,5);
        morphs[5] = dom.createMorphAt(element32,7,7);
        return morphs;
      },
      statements: [
        ["block","if",[["get","user",["loc",[null,[4,10],[4,14]]]]],[],0,1,["loc",[null,[4,4],[143,11]]]],
        ["block","if",[["get","isFiltered",["loc",[null,[156,16],[156,26]]]]],[],2,3,["loc",[null,[156,10],[161,17]]]],
        ["block","if",[["get","user",["loc",[null,[163,16],[163,20]]]]],[],4,null,["loc",[null,[163,10],[163,59]]]],
        ["block","if",[["get","isFiltered",["loc",[null,[164,16],[164,26]]]]],[],5,null,["loc",[null,[164,10],[164,133]]]],
        ["block","each",[["get","model",["loc",[null,[171,12],[171,17]]]]],[],6,null,["loc",[null,[171,4],[187,13]]]],
        ["inline","infinity-loader",[],["infinityModel",["subexpr","@mut",[["get","model",["loc",[null,[188,36],[188,41]]]]],[],[]],"loadingText","Loading...","loadedText",""],["loc",[null,[188,4],[188,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});