define('soundmondo-client/routes/voices/index', ['exports', 'ember', 'ember-infinity/mixins/route', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, InfinityRoute, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(InfinityRoute['default'], ResetScrollMixin['default'], {
    queryParams: {
      reface: {
        refreshModel: true
      },

      tags: {
        refreshModel: true
      },

      search: {
        refreshModel: true
      },

      user: {
        refreshModel: true
      }
    },

    perPageParam: 'page_size',
    totalPagesParam: 'meta.totalPages',

    model: function model(params) {
      Ember['default'].$.ajaxSettings.traditional = true;
      params['trashed'] = 'False';
      params['private'] = 'False';
      // ログイン中のユーザーが自分のSoundsをBrowseした場合はPrivateも表示
      if (params['user'] && this.get('session.secure.id')) {
        if (params['user'] === String(this.get('session.secure.id'))) {
          params['private'] = '';
        }
      }
      return this.infinityModel('voice', params);
    },

    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (urlKey === 'reface') {
        if (value) {
          // Match 'MONTAGE', 'MONTAGE-MODX', 'MONTAGE,MODX', 'MODX', 'MODXPLUS'
          if (value.indexOf('MONTAGE') === 0 || value.indexOf('MODX') === 0) {
            return this._super(['MONTAGE', 'MODX', 'MODXPLUS'], urlKey, defaultValueType);
          }
        }
      }
      return this._super(value, urlKey, defaultValueType);
    },

    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (urlKey === 'reface') {
        if (value) {
          if (value.indexOf('MONTAGE') === 0 || value.indexOf('MODX') === 0) {
            return this._super(['MONTAGE', 'MODX', 'MODXPLUS'], urlKey, defaultValueType);
          }
        }
      }
      return this._super(value, urlKey, defaultValueType);
    },

    setupController: function setupController(controller, model) {
      var rf = controller.get('refaceModels').findBy('model', controller.get('reface'));
      if (rf) {
        Ember['default'].set(rf, 'selected', true);
      }
      controller.set('model', model);
      if (controller.user) {
        controller.propertyDidChange('selectedUser');
      }
    }
  });

});