define('soundmondo-client/authenticators/login', ['exports', 'ember', 'simple-auth/authenticators/base', 'soundmondo-client/config/environment'], function (exports, Ember, Base, ENV) {

  'use strict';

  exports['default'] = Base['default'].extend({
    loginEndpoint: ENV['default'].APP.API_HOST + '/auth/',

    restore: function restore(properties) {
      var propertiesObject = Ember['default'].Object.create(properties);
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!Ember['default'].isEmpty(propertiesObject.get('token'))) {
          resolve(properties);
        } else {
          reject();
        }
      });
    },

    authenticate: function authenticate(options) {
      var _this = this;
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        var data = {};
        data['username'] = options.username;
        data['password'] = options.password;

        Ember['default'].$.post(_this.loginEndpoint, data).then(function (response) {
          Ember['default'].run(function () {
            resolve(response);
          });
        }, function (xhr) {
          Ember['default'].run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },

    invalidate: function invalidate() {
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        Ember['default'].$.ajax('https://www.yamaha.com/Paragon/yasso/signout.html', {
          dataType: 'jsonp',
          contentType: "application/json; charset=utf-8",
          jsonpCallback: "processCountryObj",
          crossDomain: true,
          success: function success(response) {
            resolve();
          },
          error: function error(reason) {
            resolve();
          }
        });
      });
    }
  });

});