define('soundmondo-client/helpers/close-new-row', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.closeNewRow = closeNewRow;

  function closeNewRow(index) {
    if (index % 2 === 0) {
      return new Ember['default'].Handlebars.SafeString('</div>');
    }
  }

  exports['default'] = Ember['default'].Helper.helper(closeNewRow);

});