define('soundmondo-client/serializers/application', ['exports', 'ember', 'soundmondo-client/serializers/drf'], function (exports, Ember, DRFSerializer) {

  'use strict';

  exports['default'] = DRFSerializer['default'].extend({
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      if (!resourceHash.hasOwnProperty('links')) {
        resourceHash['links'] = {};
      }

      modelClass.eachRelationship(function (key, relationshipMeta) {
        var payloadRelKey = this.keyForRelationship(key),
            payloadRel = undefined;

        if (!resourceHash.hasOwnProperty(payloadRelKey)) {
          return;
        }

        if (relationshipMeta.kind === 'belongsTo') {
          // Matches strings starting with: https://, http://, //, /
          payloadRel = resourceHash[payloadRelKey];
          if (!Ember['default'].isNone(payloadRel) && !Ember['default'].isNone(payloadRel.match) && typeof payloadRel.match === 'function' && payloadRel.match(/^((https?:)?\/\/|\/)\w/)) {
            resourceHash['links'][key] = resourceHash[payloadRelKey];
            delete resourceHash[payloadRelKey];
          }
        }

        if (relationshipMeta.kind === 'hasMany') {
          // Matches strings starting with: https://, http://, //, /
          payloadRel = resourceHash[payloadRelKey];
          if (!Ember['default'].isNone(payloadRel) && !Ember['default'].isNone(payloadRel.match) && typeof payloadRel.match === 'function' && payloadRel.match(/^((https?:)?\/\/|\/)\w/)) {
            resourceHash['links'][key] = resourceHash[payloadRelKey];
            delete resourceHash[payloadRelKey];
          }
        }
      }, this);

      return this._super(modelClass, resourceHash);
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      var belongsTo = snapshot.belongsTo(key);

      key = this.keyForRelationship ? this.keyForRelationship(key, "belongsTo", "serialize") : key;

      var belongsToAdapter = this.store.adapterFor(key);

      json[key] = Ember['default'].isNone(belongsTo) ? belongsTo : belongsToAdapter.buildURL(key, belongsTo.id);
    }
  });

});