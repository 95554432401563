define('soundmondo-client/services/voice-leaderboard', ['exports', 'ember', 'soundmondo-client/services/leaderboard'], function (exports, Ember, leaderboard) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend(leaderboard['default'], {
    leaderboardURL: '/voice-leaderboard/',
    reface: null,
    ordering: '-updated',
    lookup: 'voice'
  });

});