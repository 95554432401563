define('soundmondo-client/adapters/drf', ['exports', 'ember', 'ember-django-adapter/adapters/drf', 'soundmondo-client/config/environment'], function (exports, Ember, DRFAdapter, ENV) {

  'use strict';

  exports['default'] = DRFAdapter['default'].extend({
    host: Ember['default'].computed(function () {
      return ENV['default'].APP.API_HOST;
    }),

    namespace: Ember['default'].computed(function () {
      return ENV['default'].APP.API_NAMESPACE;
    })
  });

});