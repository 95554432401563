define('soundmondo-client/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    devices: Ember['default'].inject.service('devices'),
    userAgent: Ember['default'].inject.service('user-agent'),
    isConnected: Ember['default'].computed.alias('devices.isConnected'),
    isAuthenticated: Ember['default'].computed.alias('session.isAuthenticated'),
    deviceName: Ember['default'].computed.alias('devices.currentDevice.model'),
    authenticatedUser: Ember['default'].computed.alias('session.secure'),
    deviceReady: Ember['default'].computed.and('isConnected', 'isAuthenticated', 'canSync'),
    canSync: false,
    voiceQ: '',
    syncVoiceId: -1,

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
        this.transitionTo('index');
      },

      searchVoice: function searchVoice() {
        var search = this.get('voiceQ');
        this.transitionTo('voices.index', { queryParams: { search: search } });
      },

      clearSearchWord: function clearSearchWord() {
        this.set('voiceQ', '');
      },

      sync: function sync(id) {
        this.transitionTo('/voices/sync/' + id);
      }
    }
  });

});