define('soundmondo-client/locales/en/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    'application.navbar.browse': 'BROWSE',
    'application.navbar.create': 'CREATE',
    'application.navbar.sync': 'SYNC',
    'application.navbar.reface-connected': 'Connected',
    'application.navbar.reface-disconnected': 'Disconnected',
    'application.navbar.sign-in': 'Sign In',
    'application.navbar.sign-out': 'Sign Out',
    'application.navbar.search-placeholder': 'Search',

    'index.carousel.slide.montage.title': 'MONTAGE',
    'index.carousel.slide.modx.title': 'MODX',
    'index.carousel.slide.reface.title': 'reface',
    'index.carousel.slide.cp.title': 'CP',
    'index.carousel.slide.ck.title': 'CK',
    'index.carousel.slide.cp.link': 'Hear CP Sounds',
    'index.carousel.slide.yc61.link': 'YC61',

    'index.features.title': 'Featured Users',

    'index.connect.title': 'Connect your PC or iOS',
    'index.connect.step-1': 'Connect reface',
    'index.connect.step-2': 'Open Browser',
    'index.connect.step-3': 'Share and Browse',

    'index.discover.title': 'Discover, create and share Sounds',
    'index.discover.blurb': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',

    'index.map.title': 'Explore artists from all around the world.',

    'index.ranking.title': "Ranking",
    'index.ranking.favorite': "Favorite",
    'index.ranking.sync': "Sync",

    'index.products.title': "Compatible products",

    'sign-in.title': 'Sign In or Register via Social or Your Yamaha Account',
    'sign-in.register.title': 'Not a member of Soundmondo? Become a member for FREE to be able to:',
    'sign-in.register.reason-1': 'Create countless voices using any of your reface products and share them amongst the Soundmondo community.',
    'sign-in.register.reason-2': 'Browse voices created by other reface users and learn from both hobbyists and experts, including everyone in between.',
    'sign-in.register.reason-3': 'Listen to sounds and view movies created from other users in the community.',
    'sign-in.register.learn-more': 'Learn more',

    'connect.title': 'To Create a reface/MONTAGE/MODX/CP/CK Sound, Please Follow the Steps Below.',
    'connect.step-1.title': 'Step 1',
    'connect.step-1.description': 'Soundmondo requires functionality currently exclusive only to Google Chrome in order to create new voices. Please download the latest version of Google Chrome here. Please connect your reface/MONTAGE/MODX/CP/CK to your computer, mobile device or tablet via USB.',
    'connect.step-2.title': 'Step 2',
    'connect.step-2.description': 'Please connect your reface/MONTAGE/MODX/CP/CK into your laptop or desktop as illustrated below. Please note only 1 reface can be plugged into Soundmondo at any given time.',

    'voices.create.form.voice-title': 'Voice Title',
    'voices.create.form.voice-description': 'Voice Description',
    'voices.create.form.background': 'Background',
    'voices.create.form.tags': 'Tags',
    'voices.create.form.accessibility': 'Accessibility',
    'voices.create.form.options.public': 'Public',
    'voices.create.form.options.private': 'Private',
    'voices.create.form.save': 'SAVE'
  };

});