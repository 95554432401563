define('soundmondo-client/stores/cookie', ['exports', 'ember', 'simple-auth-cookie-store/stores/cookie'], function (exports, Ember, Cookie) {

  'use strict';

  exports['default'] = Cookie['default'].extend({
    renew: function renew() {
      var data = this.restore();
      if (!Ember['default'].isEmpty(data) && !Ember['default'].isEmpty(data.secure) && !Ember['default'].isEmpty(data.secure.token)) {
        data = Ember['default'].typeOf(data) === 'string' ? data : JSON.stringify(data || {});
        var expiration = this.calculateExpirationTime();
        this.write(data, expiration);
      }
    },

    persist: function persist(data) {
      if (!Ember['default'].isEmpty(data) && !Ember['default'].isEmpty(data.secure) && !Ember['default'].isEmpty(data.secure.token)) {
        data = JSON.stringify(data);
        var expiration = this.calculateExpirationTime();
        this.write(data, expiration);
        this._lastData = this.restore();
      } else {
        this.clear();
      }
    },

    clear: function clear() {
      this.write(null, 0);
      this._lastData = {};
    }
  });

});