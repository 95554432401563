define('soundmondo-client/components/file-field', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var deprecate = Ember['default'].deprecate;
  var set = Ember['default'].set;
  var on = Ember['default'].on;

  exports['default'] = Ember['default'].TextField.extend(Ember['default'].Evented, {
    type: 'file',
    attributeBindings: ['multiple', 'accept'],
    multiple: false,
    change: function change(e) {
      var input = e.target;
      if (!Ember['default'].isEmpty(input.files)) {
        this.sendAction('action', input.files);
        set(this, 'files', input.files); // to be removed in future release, needed for `files` observer to continue working
      }
    },

    _deprecateFileObserver: on('init', function () {
      var hasFilesObserver = this.hasObserverFor('files');

      deprecate('Observing the `files` attr is deprecated, use `filesDidChange` instead.', !hasFilesObserver);
    })
  });

});