define('soundmondo-client/helpers/truncate-text', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function truncateText(params, hash) {
    var _params = _slicedToArray(params, 1);

    var value = _params[0];
    var limit = hash.limit;

    var text = '';
    var suffix = '...';

    if (value != null && value.length > 0) {
      var b = 0;
      for (var i = 0; i < value.length; i++) {
        b += value.charCodeAt(i) <= 255 ? 1 : 2;
        if (b > limit) {
          return value.substr(0, i) + suffix;
        }
      }
    }

    return value;
  }

  exports['default'] = Ember['default'].Helper.helper(truncateText);

});