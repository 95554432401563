define('soundmondo-client/tests/unit/helpers/close-new-row-test', ['soundmondo-client/helpers/close-new-row', 'qunit'], function (close_new_row, qunit) {

  'use strict';

  qunit.module('Unit | Helper | close new row');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = close_new_row.closeNewRow(42);
    assert.ok(result);
  });

});