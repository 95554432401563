define('soundmondo-client/templates/tou', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 409,
            "column": 0
          }
        },
        "moduleName": "soundmondo-client/templates/tou.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Top connect spacer ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","connect-top-h");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" Top connect spacer ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" start content area block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","contentarea-block");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","con-space text-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-md-12");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"id","tou_holder");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment(" <strong>Effective: Feburary X, 2018</strong> ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Terms and Conditions of Use");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createTextNode("Thank you for visiting our website (\"");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Website");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\"). This Website is published and maintained by\nYamaha Corporation, its subsidiaries, affiliated-service providers and/or related Yamaha entities\n(\"");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Yamaha");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\"). This Terms of Use Agreement (\"");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Agreement");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\") constitutes a legally binding agreement\nmade between you, whether personally or on behalf of an entity (\"");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("you\" or \"user");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\"), and Yamaha\nconcerning your access to and use of the Website. Please carefully read this Agreement before\nusing the Website. If you do not agree to the terms of this Agreement, please do not use the\nWebsite.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createTextNode("You can use the Website in several ways: (a) you can simply view the Website; (b) you may also\naccess this Website or pages via your mobile device, or (b) you may use the interactive features that\nYamaha, its business partners, or third-party service providers make available on the Website or\nvarious mobile applications (apps) from time to time.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Ownership");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","1");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Yamaha's Proprietary Rights.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You acknowledge and agree that this Website, including,\nwithout limitation, the text, software, scripts, graphics, photos, sounds, digital images, audio\nclips, music, data, digital audio files or parameterized representations of digital audio files,\ndigital music or MIDI files, sayings, interactive features and the like (\"Materials\") and the\nregistered and unregistered trademarks, service marks and logos contained therein (\"Marks\"),\nare owned by or licensed to Yamaha, subject to copyright and other intellectual property rights\nunder Japan and foreign laws and international conventions. Materials on the Website are\nprovided to you AS IS for your information and personal use only and may not be used,\ncopied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise\nexploited for any other purposes whatsoever without the prior written consent of the\nrespective owners. Yamaha reserves all rights not expressly granted in and to the Website,\nMarks and the Materials. You agree to not engage in the use, copying, or distribution of any of\nthe Materials other than expressly permitted by Yamaha for services such as sound file sharing\nservices, and other social engagement services, including any use, copying, or distribution of\nthird parties' materials obtained through the Website for any commercial purposes, including,\nwithout, limitation, digital audio files or parameterized representations of digital audio files,\ndigital music or MIDI files;. You agree not to circumvent, disable or otherwise interfere with\nsecurity related features of the Website, digital services or features that prevent or restrict use\nor copying of any Materials or enforce limitations on use of the Website or the Materials\ntherein.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Grant of License to Yamaha.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You will retain ownership of the Contributions (defined below)\nthat you post, including, without limitation, digital audio files or parameterized representations\nof digital audio files, digital music or MIDI files, or contribute and upload to the Website. By\n\nmaking a Contribution to the Website, you hereby grant to Yamaha a perpetual, non-exclusive,\nfully-paid, royalty-free, irrevocable, sub-licensable worldwide license to use, modify, create\nderivative works of, publicly perform, publicly display, reproduce, rent, resell and distribute\nsuch Contribution through the Website, in all media now known or hereafter created and in\nany other manner in Yamaha's sole discretion subject to the condition that Yamaha will\nprovide you with worldwide attribution.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Responsibility for User's Material");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","3");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Rules Governing Public Communications, Forums and Interactive Features.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" The\nWebsite may provide forums and other features for communication. Please read Handling\nof Your Information regarding the use of Soundmondo posted on this Website to\nunderstand your privacy protections. You are entirely responsible for the content of, and\nany harm resulting from any postings to the Website, including, without limitation, digital\naudio files or parameterized representations of digital audio files, digital music or MIDI files;\nand all other submissions to the Website (collectively, ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("\"Contributions\"");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("). When you create or\nmake available a Contribution, you represent and warrant that you:\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("ol");
        dom.setAttribute(el9,"start","1");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("own or have sufficient rights to post your Contributions on or through the Website;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not post Contributions that violate the privacy rights, publicity rights, copyrights,\ndigital rights management technologies, copyright management information, patent\nrights, trademark rights, trade secrets, contract rights or any other rights of Yamaha or\nany other person;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("have fully complied with any third-party licenses relating to Contributions, agree to pay\nfor all royalties, fees and any other monies owing any person by reason of\nContributions that you posted to or through the Website;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not post Contributions that: (i) are defamatory, damaging, disruptive, unlawful,\ninaccurate, obscene, lewd, lascivious, filthy, threatening, excessively violent, harassing,\nadvocate the violent overthrow of the government of the United States, incite,\nencourage or threaten immediate physical harm against another, including but not\nlimited to Communications that promote racism, bigotry, sexism, religious intolerance\nor harm against any group or individual, or (ii) contain material that solicits personal\ninformation from anyone under 18 or exploit people under the age of 18 in a sexual or\nviolent manner;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not post Contributions that contain advertisements or solicit any person to buy or\nsell any products or services (other than Yamaha products and services) unless\nauthorized by Yamaha;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not use the Website for any commercial purpose, including collecting usernames\nand/or email addresses of other users by electronic or other means for the purpose of\nsending unsolicited email or other electronic communications, or engaging in\nunauthorized framing of, or linking to, the Website without the express written consent\nof Yamaha;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not post Contributions that constitute, contain, install or attempt to install or\npromote spyware, malware or other computer codes, whether on Yamaha's or others'\ncomputers, devices, or equipment, designated to enable you or others to gather\ninformation about or monitor the online or other activities of another party;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not transmit chain letters, bulk or junk email, or interfere with, disrupt, or create an\nundue burden on the Website or the networks or services connected to the Website,\nincluding, without limitation, hacking into the Website;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will only submit information that is truthful and accurate; you will maintain the\naccuracy of such information, and will not impersonate any other person or entity, sell\nor let others use your profile or password, provide false or misleading identification or\naddress information, or invade the privacy, or violate the personal or proprietary right,\nof any person or entity;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not provide material support or resources (or conceal or disguise the nature,\nlocation, source or ownership of material support or resources) to any organization(s)\ndesignated by Japan government as anti-social forces as an organized crime group, a\nmember of an organized crime group, a related company or association of an organized\ncrime group, and any other equivalent person of above;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not accept payment or anything of value from a third person in exchange for your\nperforming any activity on the Website;\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("will not use the system to send unsolicited or commercial emails, bulletins, comments\nor other communications. As a reasonable estimation of such harm, you agree to pay\nYamaha \\5,000 for each such unsolicited email or other unsolicited communication you\nsend through the Website; and\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("li");
        var el11 = dom.createTextNode("Further, you represent that you are a resident of a country that does not restrict or\nprohibit your use of websites or apps such as this Website or Yamaha's apps, and that\nyou will comply with all local rules and laws of your country, province, state, territory or\ngeopolitical region regarding online conduct and acceptable use of websites and this\nWebsite.\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("No Commercial Use by Users.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" The Website is for the personal use of users only and may not\nbe used in connection with any commercial endeavors, except those that are specifically\nendorsed or approved by Yamaha. You shall not engage in unauthorized framing of or linking\nto the Website without the express written consent of Yamaha.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Device information.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You agree that when you are connected to our services via custom\napplications (apps) or Wi-Fi, we may collect device-specific information such as your hardware\nmodel, operating system, unique device identifiers, and mobile network information including\nphone number. We may associate your unique device identifier or phone number with\ninformation that you may have used to register for certain services or purchase products.\nServer logs that are maintained by us or by our third-party service providers may contain\ninformation about the services that you have used, and your device-specific information. We\nmay also maintain digital communications logs that track information like your phone number,\ncalling-party number, forwarding numbers, time and date of calls, duration of calls, SMS\nrouting information and types of calls. We may use your device's IP address or MAC address to\ntrack device-event information such as crashes, system activity, hardware settings, browser\ntype, browser language, the date and time of your request and referral URL.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Specialized information.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You agree that we may collect and store information (including\npersonal information) locally on your device using mechanisms such as browser web storage\n(including HTML 5) and application data caches. We may also use cookies and anonymous\nidentifiers when you interact with services such as advertising services offered by us or our\nbusiness partners. Certain services may include a unique application number in connection\nwith the installation of apps. This number and information, including, without limitation,\noperating system type and app version number may be sent to our service providers when\nyou install, update or uninstall apps. You agree to this type of transfer and use of your\npersonal and device information. You agree that we may also collect and process information\nabout your actual location. For example, we may acquire your device's GPS signals. We may\nalso use various technologies to determine your location based on information collected via\nour Wi-Fi access points, custom sensors, and cell towers.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Yamaha's Rights to Manage Website and Terminate Users");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","7");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Website Management.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Yamaha reserves the right but does not undertake the obligation to:\n(a) monitor the Website for violations of this Agreement; (b) take appropriate legal action\nagainst anyone who, in Yamaha's sole discretion, violates this Agreement, including, without\nlimitation, reporting you to local, national, or international law enforcement authorities; and (c)\nto otherwise manage the Website in a manner designed to protect the rights and property of\nYamaha and others and to facilitate the proper functioning of the Website.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Yamaha's Right to Terminate Users.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" WITHOUT LIMITING ANY OTHER PROVISION OF THIS\nAGREEMENT, YAMAHA RESERVES THE RIGHT TO, IN YAMAHA'S SOLE DISCRETION; TERMINATE\nTHE ACCOUNTS OF REPEAT INFRINGERS.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Sweepstakes; Contests");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            This Site may contain sweepstakes or contests that require you to send in material or information\nabout yourself or may offer prizes. Each sweepstakes or contest has its own rules which you must\nread and agree to before you may enter.");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Legal Disclaimers & Liability");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","9");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("DISCLAIMER OF WARRANTIES.");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" YOUR USE OF THIS WEBSITE IS AT YOUR SOLE RISK. THE\nWEBSITE, CONTENT AND WEBSITE SERVICES ARE PROVIDED ON AN \"AS IS\" AND \"AS\nAVAILABLE\" BASIS. YAMAHA MAKES NO WARRANTY THAT\n(A) THE WEBSITE, CONTENT OR SERVICE PROVIDED BY OR THROUGH THIS WEBSITE OR ITS\nSERVICE PROVIDERS OR LINKED WEBSITES WILL MEET YOUR REQUIREMENTS,\n(B) THE WEBSITE, CONTENT OR THE SERVICE PROVIDED BY OR THROUGH THIS WEBSITE WILL\nBE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,\n(C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE, CONTENT OR\nWEBSITE SERVICES WILL BE ACCURATE OR RELIABLE,\n(D) THE QUALITY OF ANY CONTENT, PRODUCTS, SERVICES, INFORMATION, OR OTHER\nMATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THIS WEBSITE WILL MEET YOUR\nEXPECTATIONS,\n(E) ANY ERRORS IN THE SOFTWARE RELATING TO THIS WEBSITE WILL BE CORRECTED, OR\n(F) THAT THIS WEBSITE, CONTENT OR ANY SERVICES PROVIDED BY OR THROUGH IT WILL BE\nFREE FROM VIRUSES, HARMFUL SOFTWARE OR OTHER DEFECTS");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("ANY MATERIAL OBTAINED THROUGH THE USE OF THIS WEBSITE IS OBTAINED AT YOUR OWN\nDISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR\nCOMPUTER SYSTEM, INCLUDING, WITHOUT LIMITATION, PERSONAL DIGITAL ASSISTANTS,\nSMART PHONES, NOTEBOOKS, NOTEPADS, OR ANY SIMILAR DEVICES, OR LOSS OF DATA OR\nDATA CORRUPTION THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM\nYAMAHA OR THROUGH OR FROM THIS WEBSITE, SHALL CREATE ANY WARRANTY NOT\nEXPRESSLY STATED IN THESE TERMS AND CONDITIONS.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("SOME COUNTRIES, PROVINCES, TERRITORIES, STATES OR GEOPOLITICAL REGIONS MAY NOT\nALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE DISCLAIMERS OR PORTIONS\nOF THEM MAY NOT APPLY TO YOU. YOUR LEGAL RIGHTS MAY VARY FROM JURISDICTION TO\nJURISDICTION.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("LIMITATION OF LIABILITY.");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" IN NO EVENT SHALL YAMAHA OR ITS SERVICE PROVIDERS BE\nLIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, FORESEEN OR UNFORESEEN,\nINCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT\nLIMITED TO, DAMAGES FOR LOSS OF TIME OR LOSS OF PROFITS, GOODWILL, USE, DATA OR\nOTHER INTANGIBLE LOSSES (EVEN IF YAMAHA OR ITS SERVICE PROVIDERS HAVE BEEN ADVISED\nOF THE POSSIBILITY OF SUCH DAMAGES AND HOWEVER ARISING, INCLUDING NEGLIGENCE),\nRESULTING FROM OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, THE WEBSITE\nSERVICES AND/OR ANY CONTENT ON THE WEBSITE. NOTWITHSTANDING ANYTHING TO THE\nCONTRARY HEREIN, IN NO EVENT WILL YAMAHA'S LIABILITY TO YOU FOR ANY CAUSE\nWHATSOEVER BE IN EXCESS OF THE AMOUNT YOU PAID TO YAMAHA DURING THE SIX\nMONTHS PRIOR TO THE ALLEGED OCCURRENCE OR \\10,000, WHICHEVER IS LESS.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN\nDAMAGES, YAMAHA'S LIABILITY IN THOSE JURISDICTIONS SHALL BE LIMITED TO THE FULLEST\nEXTENT PERMITTED BY LAW.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("THIS PROVISION AND THESE REMEDIES HAVE BEEN AN IMPORTANT CONSIDERATION FOR\nYAMAHA IN OFFERING THE WEBSITE. YOU AGREE TO THESE REMEDIES AND WAIVE ANY RIGHTS\nCONTRARY TO THESE REMEDIES, TO THE MAXIMUM EXTENT PERMITTED BY LAW, IF YOU\nACCESS OR USE THIS WEBSITE.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Indemnification.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You agree to defend, indemnify and hold harmless Yamaha, its officers,\ndirectors, employees, affiliates, licensees, partners, subsidiaries and agents, third parties and\nservice providers acting on Yamaha's behalf (\"Indemnified Parties\") from and against any and\nall claims, actions, losses, demands and liability costs and expenses (including without\nlimitation reasonable legal and accounting fees), arising from:\n(a) your use of this Website or Website Services;\n(b) your breach of this Agreement;\n(c) any Contributions you post to the Website including, without limitation, digital audio files or\nparameterized representations of digital audio files, digital music or MIDI files; and\n(d) any breach of your representations and warranties set forth herein.\nYamaha, at your expense and Yamaha's discretion, may provide notice to you of Yamaha's\nelection to assist you in defending any such claim, suit or proceeding, but doing so will not\nexcuse you from your indemnity obligations.\n\nYou agree to pay reasonable attorney and expert witness fees and costs incurred by Yamaha\nor its agents or service providers in enforcing this provision.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Release.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You remise, release, acquit, satisfy, and forever discharge Yamaha, its officers,\ndirectors, employees, affiliates, licensees, partners, subsidiaries and agents, third parties and\nservice providers acting on Yamaha's behalf and each of them from any and all causes of\naction, claims for relief, lawsuits, charges, complaints, debts, liens, contracts, agreements,\npromises, liabilities, demands, damages, losses, rights, benefits, obligations, attorneys' fees,\ncosts or expenses of any kind or nature whatsoever, in law, equity or otherwise which you ever\nhad, have or may have related in any way to your use of the Website and from all manner of\nactions, causes of action, claims, suits, debts, covenants, contracts, controversies, agreements,\npromises, claims, and demands you may or may not have or ever had, from the beginning of\ntime.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Choice of Law and Forum.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" You and Yamaha agree to submit to the exclusive jurisdiction of\nthe appropriate state or federal court where subject matter jurisdiction and venue is proper\nfor Tokyo, Japan. You and Yamaha agree that any disputes shall be resolved under the\nsubstantive law of Japan (exclusive of its choice of law provisions). The Convention for the\nInternational Sale of Goods shall not apply.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Term and Termination");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","14");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Term and Termination.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" This Agreement shall remain in full force and effect while you use the\nWebsite or while you are employed by Yamaha. You may terminate your use or participation at\nany time, for any reason, by ceasing use of the Website. Yamaha may terminate your use or\nparticipation at any time, without warning.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Billings and Charges");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","15");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createElement("strong");
        var el11 = dom.createTextNode("Billing and Charges.");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" If applicable to your transactions conducted through the Website,\nYamaha reserves the right to charge the then-current prices and fees posted on this Website\nand as modified from time to time without advance notice to you. If you submit an order or\nrequest for services for which a payment or fee is required, you hereby authorize Yamaha or\nits third-party service providers to charge your credit card for all applicable fees, taxes,\nshipping/handling and charges, if applicable, including fees and charges applied by third\nparties or other service providers necessary to complete your request for services.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("Yamaha reserves the right to modify fees and charges applicable at the time of renewal of\nsubscriptions or services. Any periodic subscription fees will be billed automatically to your\n\ndesignated credit card at the start of each renewal period and you authorize such automatic\nbilling for renewals.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("You acknowledge that it is your responsibility to notify Yamaha or its third-party service\nproviders of any changes to your credit card, including its expiration.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("Charges will be those posted at time of order submission. You agree to pay any taxes,\nincluding sales or use taxes, shipping and handling fees as posted. All fees shall be paid in U.S.\ndollars. Charges will be posted at time of order submission to any credit card you have\nauthorized. You agree to make payment within thirty (30) days of billing by your credit card\nservice. Acceptance of late or partial payments (even if marked \"Paid in Full\") shall not waive\nany of Yamaha's rights to collect amounts due. Delinquent payments may result in the\nimposition of a late fee, at the rate of one percent of the unpaid balance per month, or the\nmaximum allowable under applicable state law, whichever is higher. If payment becomes\ndelinquent, Yamaha may suspend, disable or terminate services or access to you at this\nWebsite and related sites.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("You acknowledge and agree that you will notify Yamaha or its responsible third-party service\nprovider of any billing problems or discrepancies within sixty (60) days after they first appear\non your credit card account statement. If you do not notify Yamaha or its third-party service\nprovider within sixty (60) days, you waive any right to dispute such problems or discrepancies.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Passwords");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","16");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Passwords and User Accounts.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" When you create an account with the Website, you will be\nasked to supply a password. You must keep your password confidential, and are responsible\nfor all use of your password and account. You may not disclose or share your password with\nany third parties or use your password for any unauthorized purpose. You are obligated to\nimmediately report a lost or stolen password or username to Yamaha.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("General Provisions");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7,"start","17");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Modifications.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Yamaha may at any time revise this Agreement, as well as the Website.\nYamaha will alert all users with whom it maintains email information of such modifications.\nAny and all changes to this Agreement will also be reflected on the Website. IF YOU ARE A\nREGISTERED USER, AND, IF APPLICABLE, YOU MUST CLICK TO ACCEPT THE NEW AGREEMENT\nTHE NEXT TIME YOU LOG ON TO THE SITE IN ORDER TO BE ABLE TO CONTINUE TO PURCHASE\nYAMAHA PRODUCTS. IF YOU ARE NOT A REGISTERED USER, YOU AGREE TO BE BOUND TO ANY\nCHANGES TO THIS AGREEMENT WHEN YOU USE THE WEBSITE AFTER YOU ARE NOTIFIED OF\nANY SUCH MODIFICATION. YOU SHOULD KEEP YOUR CONTACT INFORMATION CURRENT TO\nENSURE YOU ARE NOTIFIED OF ANY CHANGES.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Site Content Disclaimer.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" On occasion, information on this Website may contain errors,\nincluding, without limitation, typographical errors, inaccuracies, or omissions related to\nproduct availability, special offers, product promotions, pricing information, product\ndescriptions, or product shipping charges and transit times. Yamaha reserves the right to, at\nany time without prior notice, correct any errors, inaccuracies or omissions and to change or\nupdate information or cancel orders if any information on the Website is inaccurate (including\nafter you have submitted your order).\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Notices.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" When you visit this Website or send e-mails to us, you are communicating with us\nelectronically. You consent to receive communications from us electronically. We will\ncommunicate with you by e-mail or by posting notices on this Website. You agree that all\nagreements, notices, disclosures and other communications that we provide to you\nelectronically satisfy any legal requirement that such communications be in writing.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Third-Party Links.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Third-party links on this Website may direct you to third-party websites\nthat are not affiliated with Yamaha that may be located in different countries, and those\nwebsites and the products sold on those websites are likely to be subject to the originating\ncountry's regulatory or product requirements. Yamaha is not liable for any harm or damages\nrelated to the purchase or use of goods, services, resources, content, or any other transactions\n(\"Third-Party Products\") made in connection with any third-party websites. Please review\ncarefully the third party's policies and practices and make sure you understand them before\nyou engage in any transaction. Complaints, claims, concerns, or questions regarding Third-\nParty Products should be directed to the third party.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Entire Agreement.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" This Agreement constitutes the entire agreement between you and\nYamaha regarding the use of the Website. This Agreement supersedes any and all previous\nproposals, negotiations, writings and other communication between you and Yamaha.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Waiver.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" The failure of Yamaha to exercise or enforce any right or provision of this Agreement\nshall not operate as a waiver of such right or provision.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Assignment.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" This Agreement will be binding upon and inure to the benefit of each party and\ntheir respective assigns. You may not assign your rights under this Agreement to any third\nparty; Yamaha may assign its rights under this Agreement without condition.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Severability.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" This Agreement operates to the fullest extent permissible by law. If any provision\nor part of a provision of this Agreement is unlawful, void or unenforceable, that provision or\n\npart of the provision is deemed severable from this Agreement and does not affect the validity\nand enforceability of any remaining provisions.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("strong");
        var el10 = dom.createTextNode("Survival.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" The provisions herein dealing directly or indirectly with intellectual property,\ndisclaimers of warranties, limitation of liability, compliance with laws and regulations, security,\ndispute resolution and use restrictions and prohibitions continue to apply and shall survive the\ntermination or expiration of any relationship between you and Yamaha.\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End content area block ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});