define('soundmondo-client/authorizers/login', ['exports', 'ember', 'simple-auth/authorizers/base'], function (exports, Ember, Base) {

  'use strict';

  exports['default'] = Base['default'].extend({
    authorize: function authorize(jqXHR) {
      var secureData = this.get('session.secure');
      var userToken = secureData['token'];

      if (this.get('session.isAuthenticated') && !Ember['default'].isEmpty(userToken)) {
        jqXHR.setRequestHeader('Authorization', 'Token ' + userToken);
      }
    }
  });

});