define('soundmondo-client/routes/index', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        queryParams: {
            by: {
                refreshModel: true
            },

            reface: {
                refreshModel: true
            }
        },

        by: null,
        reface: null,

        buildurl: function buildurl() {
            var url = ENV['default'].APP.API_ENDPOINT + '/voices/ranking/?by=' + this.get('by');

            if (this.get('reface')) {
                url += '&reface=' + this.get('reface');
            }

            return url;
        },

        model: function model(params) {
            if (params.by === 'fav' || params.by === 'sync') {
                this.set('by', params.by);
            } else {
                this.set('by', 'sync');
            }

            this.set('reface', params.reface);

            var url = this.buildurl(),
                PromiseObject = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin);

            return PromiseObject.create({
                promise: Ember['default'].$.getJSON(url).then(function (data) {
                    if (data) {
                        return data.map(function (item, index) {
                            item.updated_at = new Date(item.updated).toLocaleDateString();
                            item.le5 = index <= 4;
                            item.ge6 = index >= 5;
                            return item;
                        });
                    }

                    return [];
                })
            });
        },

        setupController: function setupController(controller, model) {
            controller.get('rankingBys').setEach('selected', false);
            var by = controller.get('rankingBys').findBy('by', this.get('by'));
            Ember['default'].set(by, 'selected', true);

            controller.get('refaceModels').setEach('selected', false);
            if (this.get('reface')) {
                var reface = controller.get('refaceModels').findBy('model', this.get('reface'));
                Ember['default'].set(reface, 'selected', true);
            }

            controller.set('rankingItems', model);
        }
    });

});