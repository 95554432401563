define('soundmondo-client/tests/unit/helpers/strip-left-test', ['soundmondo-client/helpers/strip-http', 'qunit'], function (strip_http, qunit) {

  'use strict';

  qunit.module('Unit | Helper | strip http');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = strip_http.stripHttp(42);
    assert.ok(result);
  });

});