define('soundmondo-client/serializers/user', ['exports', 'ember', 'soundmondo-client/serializers/drf'], function (exports, Ember, DRFSerializer) {

    'use strict';

    exports['default'] = DRFSerializer['default'].extend({
        attrs: {
            photo: { serialize: false }
        }
    });

});