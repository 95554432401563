define('soundmondo-client/serializers/set', ['exports', 'ember', 'soundmondo-client/serializers/drf'], function (exports, Ember, DRFSerializer) {

  'use strict';

  exports['default'] = DRFSerializer['default'].extend({
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      key = this.keyForRelationship ? this.keyForRelationship(key, "hasMany", "serialize") : key;

      var hasManyAdapter = this.store.adapterFor(key);

      json[key] = Ember['default'].isNone(snapshot.id) ? null : hasManyAdapter.buildURL(key, snapshot.id);
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      var belongsTo = snapshot.belongsTo(key);

      key = this.keyForRelationship ? this.keyForRelationship(key, "belongsTo", "serialize") : key;

      var belongsToAdapter = this.store.adapterFor(key);

      json[key] = Ember['default'].isNone(belongsTo) ? belongsTo : belongsToAdapter.buildURL(key, belongsTo.id);
    }
  });

});