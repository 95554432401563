define('soundmondo-client/models/set', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    trashed: DS['default'].attr('boolean'),
    created: DS['default'].attr('date'),
    updated: DS['default'].attr('date'),
    user: DS['default'].belongsTo('user', { async: true }),
    voices: DS['default'].hasMany('voice', { async: true, inverse: null })
  });

});