define('soundmondo-client/controllers/sign-in', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    aggreement: Ember['default'].inject.service('tou-agreement'),
    isAgreed: Ember['default'].computed.alias('aggreement.isAgreed'),
    showDevLoginForm: ENV['default'].APP.SHOW_DEV_LOGIN_FORM,

    reset: function reset() {
      this.setProperties({
        error: null,
        username: '',
        password: '',
        touAgreement: false,
        hoyiAgreemtnt: false
      });
    },

    actions: {
      login: function login() {
        var _this = this;
        var credentials = this.getProperties('username', 'password');
        if (!credentials.username || !credentials.password) {
          this.set('error', 'Username/Password must be supplied.');
        } else {
          this.get('session').authenticate('authenticator:login', credentials).then(function () {
            var data = _this.get('session.secure');
            _this.get('store').push('user', {
              id: data['id'],
              firstName: data['first_name'],
              lastName: data['last_name'],
              bio: data['bio'],
              preferences: data['preferences'],
              social: data['social']
            });
          }, function () {
            _this.set('error', 'Username/Password invalid.');
          });
        }
      },

      agree: function agree() {
        var agreements = this.getProperties('touAgreement', 'hoyiAgreemtnt');
        if (agreements.touAgreement && agreements.hoyiAgreemtnt) {
          Cookies.set('agree-new-tou-and-privacy', '1', { expires: 365 * 10 });
        } else {
          this.set('error', 'You must agree to the above Terms and Conditions of Use and Handling of Your Information.');
        }
        location.reload();
      },

      disagree: function disagree() {
        this.transitionTo('index');
      }
    }
  });

});