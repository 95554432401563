define('soundmondo-client/routes/user', ['exports', 'ember', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ResetScrollMixin['default'], {

    model: function model(params) {
      return Ember['default'].RSVP.hash({
        user: this.get('store').find('user', params.user_id)
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.controller.clearEdit();
        this.controller.clearErrors();
        this.controller.clearInfo();
      }
    }
  });

});