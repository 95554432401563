define('soundmondo-client/models/user', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    firstName: DS['default'].attr('string'),
    lastName: DS['default'].attr('string'),
    displayName: DS['default'].attr('string'),
    bio: DS['default'].attr('string'),
    location: DS['default'].attr('string'),
    gravatar: DS['default'].attr('string'),
    preferences: DS['default'].attr(),
    social: DS['default'].attr(),
    photo: DS['default'].attr(),
    sets: DS['default'].hasMany('set', { async: true }),
    soundsCount: DS['default'].attr('number'),

    fullName: Ember['default'].computed('displayName', 'firstName', 'lastName', function () {
      var firstName = this.get('firstNamme'),
          lastName = this.get('lastName'),
          displayName = this.get('displayName');

      if (!Ember['default'].isEmpty(displayName)) {
        return this.get('displayName');
      } else {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
    })
  });

});