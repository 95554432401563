define('soundmondo-client/services/devices', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (navigator.requestMIDIAccess === undefined) {
        this.set('error', 'Web MIDI not supported. Please use Google Chrome.');
        return;
      }
      var _this = this;
      navigator.requestMIDIAccess({ sysex: true }).then(function (midiAccess) {
        midiAccess.onstatechange = function (ev) {
          _this.updateDeviceStatus(ev.currentTarget);
        };
        _this.updateDeviceStatus(midiAccess);
      }, function (msg) {
        _this.set('error', msg);
        _this.set('connected', false);
      });
    },

    _midi: null,
    _connectedDevices: [],
    _currentDeviceIdx: 0,
    _refacePanelEl: null,
    error: null,

    updateDeviceStatus: function updateDeviceStatus(midiAccess) {
      var deviceMap = {},
          deviceId,
          input,
          output,
          name;
      this.set('_midi', midiAccess);
      this.set('_connectedDevices', []);

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = midiAccess.inputs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var entry = _step.value;

          deviceId = entry[0];
          input = entry[1];
          name = input.name;

          if (input.state === 'connected') {
            switch (name) {
              case (name.match(/reface CS/) || {}).input:
                deviceMap['CS'] = deviceMap['CS'] || {};
                deviceMap['CS']['inputId'] = deviceId;
                deviceMap['CS']['inputDevice'] = input;
                break;

              case (name.match(/reface DX/) || {}).input:
                deviceMap['DX'] = deviceMap['DX'] || {};
                deviceMap['DX']['inputId'] = deviceId;
                deviceMap['DX']['inputDevice'] = input;
                break;

              case (name.match(/reface CP/) || {}).input:
                deviceMap['CP'] = deviceMap['CP'] || {};
                deviceMap['CP']['inputId'] = deviceId;
                deviceMap['CP']['inputDevice'] = input;
                break;

              case (name.match(/reface YC/) || {}).input:
                deviceMap['YC'] = deviceMap['YC'] || {};
                deviceMap['YC']['inputId'] = deviceId;
                deviceMap['YC']['inputDevice'] = input;
                break;

              case (name.match(/MONTAGE\sM/) || {}).input:
                if (deviceId === "input-0" || name.match(/^MONTAGE\sM[^0-9]+1/) || name.match(/^MONTAGE\sM\s\d\s[^0-9]+1/) != null) {
                  deviceMap['MMONTAGEM'] = deviceMap['MMONTAGEM'] || {};
                  deviceMap['MMONTAGEM']['inputId'] = deviceId;
                  deviceMap['MMONTAGEM']['inputDevice'] = input;
                }
                break;

              case (name.match(/MONTAGE/) || {}).input:
                if (deviceId === "input-0" || name.match(/^MONTAGE[^0-9]+1/) != null) {
                  deviceMap['MONTAGE'] = deviceMap['MONTAGE'] || {};
                  deviceMap['MONTAGE']['inputId'] = deviceId;
                  deviceMap['MONTAGE']['inputDevice'] = input;
                }
                break;

              // MODX, MODXPLUS で切り分けられない
              case (name.match(/MODX/) || {}).input:
                if (deviceId === "input-0" || name.match(/^MODX[^0-9]+1/) != null) {
                  deviceMap['MODX'] = deviceMap['MODX'] || {};
                  deviceMap['MODX']['inputId'] = deviceId;
                  deviceMap['MODX']['inputDevice'] = input;
                }
                break;

              case (name.match(/CP88\/CP73/) || {}).input:
                if (deviceId === "input-0" || name.match(/^CP88\/CP73[^0-9]+1/) != null) {
                  deviceMap['CP8873'] = deviceMap['CP8873'] || {};
                  deviceMap['CP8873']['inputId'] = deviceId;
                  deviceMap['CP8873']['inputDevice'] = input;
                }
                break;

              case (name.match(/^YC\sSeries/i) || {}).input:
                if (deviceId === "input-0" || name.match(/^YC\sSeries[^0-9]+1/i) != null) {
                  deviceMap['YC61'] = deviceMap['YC61'] || {};
                  deviceMap['YC61']['inputId'] = deviceId;
                  deviceMap['YC61']['inputDevice'] = input;
                }
                break;

              case (name.match(/^CK\sSeries/i) || {}).input:
                if (deviceId === "input-0" || name.match(/^CK\sSeries[^0-9]+1/i) != null) {
                  deviceMap['CK8861'] = deviceMap['CK8861'] || {};
                  deviceMap['CK8861']['inputId'] = deviceId;
                  deviceMap['CK8861']['inputDevice'] = input;
                }
                break;

              default:
                break;
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = midiAccess.outputs[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var outputDevice = _step2.value;

          deviceId = outputDevice[0];
          output = outputDevice[1];
          name = output.name;

          if (output.state === 'connected') {
            switch (name) {
              case (name.match(/reface CS/) || {}).input:
                if (deviceMap['CS']) {
                  deviceMap['CS']['outputId'] = deviceId;
                  deviceMap['CS']['outputDevice'] = output;
                }
                break;

              case (name.match(/reface DX/) || {}).input:
                if (deviceMap['DX']) {
                  deviceMap['DX']['outputId'] = deviceId;
                  deviceMap['DX']['outputDevice'] = output;
                }
                break;

              case (name.match(/reface CP/) || {}).input:
                if (deviceMap['CP']) {
                  deviceMap['CP']['outputId'] = deviceId;
                  deviceMap['CP']['outputDevice'] = output;
                }
                break;

              case (name.match(/reface YC/) || {}).input:
                if (deviceMap['YC']) {
                  deviceMap['YC']['outputId'] = deviceId;
                  deviceMap['YC']['outputDevice'] = output;
                }
                break;

              case (name.match(/MONTAGE\sM/) || {}).input:
                if (deviceMap['MMONTAGEM']) {
                  if (deviceId === "output-1" || name.match(/^MONTAGE\sM[^0-9]+1/) || name.match(/^MONTAGE\sM\s\d\s[^0-9]+1/) != null) {
                    deviceMap['MMONTAGEM']['outputId'] = deviceId;
                    deviceMap['MMONTAGEM']['outputDevice'] = output;
                  }
                }
                break;

              case (name.match(/MONTAGE/) || {}).input:
                if (deviceMap['MONTAGE']) {
                  if (deviceId === "output-1" || name.match(/^MONTAGE[^0-9]+1/) != null) {
                    deviceMap['MONTAGE']['outputId'] = deviceId;
                    deviceMap['MONTAGE']['outputDevice'] = output;
                  }
                }
                break;

              // MODX, MODXPLUS で切り分けられない
              case (name.match(/MODX/) || {}).input:
                if (deviceMap['MODX']) {
                  if (deviceId === "output-1" || name.match(/^MODX[^0-9]+1/) != null) {
                    deviceMap['MODX']['outputId'] = deviceId;
                    deviceMap['MODX']['outputDevice'] = output;
                  }
                }
                break;

              case (name.match(/CP88\/CP73/) || {}).input:
                if (deviceMap['CP8873']) {
                  if (deviceId === "output-1" || name.match(/^CP88\/CP73[^0-9]+1/) != null) {
                    deviceMap['CP8873']['outputId'] = deviceId;
                    deviceMap['CP8873']['outputDevice'] = output;
                  }
                }
                break;

              case (name.match(/^YC\sSeries/i) || {}).input:
                if (deviceMap['YC61']) {
                  if (deviceId === "output-1" || name.match(/^YC\sSeries[^0-9]+1/i) != null) {
                    deviceMap['YC61']['outputId'] = deviceId;
                    deviceMap['YC61']['outputDevice'] = output;
                  }
                }
                break;

              case (name.match(/^CK\sSeries/i) || {}).input:
                if (deviceMap['CK8861']) {
                  if (deviceId === "output-1" || name.match(/^CK\sSeries[^0-9]+1/i) != null) {
                    deviceMap['CK8861']['outputId'] = deviceId;
                    deviceMap['CK8861']['outputDevice'] = output;
                  }
                }
                break;

              default:
                break;
            }
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2['return']) {
            _iterator2['return']();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var _this = this;
      Ember['default'].run.schedule('sync', this, function () {
        Ember['default'].$.each(deviceMap, function (model, deviceObj) {
          deviceObj['model'] = model;
          _this.get('_connectedDevices').pushObject(deviceObj);
        });
      });
    },

    isConnected: Ember['default'].computed('_connectedDevices.@each', function () {
      return this.get('_connectedDevices').length > 0;
    }),

    currentDevice: Ember['default'].computed('_connectedDevices.@each', function () {
      if (this.get('_connectedDevices').length > 0) {
        return this.get('_connectedDevices')[this.get('_currentDeviceIdx')];
      }
    }),

    isCS: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'CS';
      }
    }),

    isDX: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'DX';
      }
    }),

    isCP: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'CP';
      }
    }),

    isYC: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'YC';
      }
    }),

    isMONTAGE: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'MONTAGE';
      }
    }),

    isMODX: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'MODX';
      }
    }),

    isCP8873: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'CP8873';
      }
    }),

    isCK8861: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'CK8861';
      }
    }),

    isMONTAGEM: Ember['default'].computed('currentDevice', function () {
      if (this.get('currentDevice')) {
        return this.get('currentDevice').model === 'MMONTAGEM';
      }
    })
  });

});