define('soundmondo-client/tests/unit/models/set-membership-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('set-membership', 'Unit | Model | set membership', {
    // Specify the other units that are required for this test.
    needs: ['model:set', 'model:voice']
  });

  ember_qunit.test('it exists', function (assert) {
    var model = this.subject();
    // var store = this.store();
    assert.ok(!!model);
  });

});