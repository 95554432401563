define('soundmondo-client/components/video-background', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    player: null,
    defaults: {
      autoplay: 0,
      autohide: 1,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
      controls: 0,
      disablekb: 1,
      enablejsapi: 0,
      iv_load_policy: 3
    },

    createPlayer: function createPlayer() {
      var playerVars = this.get('defaults'),
          player = new YT.Player('tv', {
        events: {
          'onReady': this.onPlayerReady.bind(this),
          'onStateChange': this.onPlayerStateChange.bind(this)
        },
        playerVars: playerVars
      });
      this.set('player', player);
      Ember['default'].$(window).on('load resize', this.onVideoResize.bind(this));
    },

    loadVideo: function loadVideo() {
      var videoId = this.get('videoId'),
          player = this.get('player');
      if (videoId && player) {
        player.loadVideoById({
          'videoId': videoId,
          'startSeconds': 0,
          'endSeconds': 130,
          'suggestedQuality': 'hd720'
        });
        player.mute();
      }
    },

    onPlayerReady: function onPlayerReady() {
      this.loadVideo();
    },

    onPlayerStateChange: function onPlayerStateChange(ev) {
      var player = this.get('player');
      if (ev.data === 1) {
        Ember['default'].$('#tv').addClass('yt-active');
      } else if (ev.data === 0) {
        player.seekTo(0);
      }
    },

    willInsertElement: function willInsertElement() {
      var videoId = this.get('videoId'),
          _this = this;
      if (videoId) {
        if (typeof YT === "undefined") {
          Ember['default'].$.getScript("https://www.youtube.com/player_api").then(function () {
            window.onYouTubePlayerAPIReady = _this.createPlayer.bind(_this);
          });
        } else {
          Ember['default'].run.later(function () {
            _this.createPlayer();
            _this.onVideoResize();
          }, 500);
        }
      }
    },

    willDestroyElement: function willDestroyElement() {
      var player = this.get('player');
      if (player) {
        player.destroy();
        this.set('player', null);
      }
    },

    onVideoResize: function onVideoResize() {
      var w = $(window).width() + 200,
          h = $(window).height() + 200,
          player = this.get('player');

      if (player) {
        if (w / h > 16 / 9) {
          player.setSize(w, w / 16 * 9);
          $('.yt-tv .yt-screen').css({ 'left': '0px' });
        } else {
          player.setSize(h / 9 * 16, h);
          $('.yt-tv .yt-screen').css({ 'left': -($('.yt-tv .yt-screen').outerWidth() - w) / 2 });
        }
      }
    }
  });

});