define('soundmondo-client/models/feature', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    user: DS['default'].belongsTo('user', { async: true }),

    userId: DS['default'].attr('string'),

    firstName: DS['default'].attr('string'),
    lastName: DS['default'].attr('string'),
    displayName: DS['default'].attr('string'),

    comments: DS['default'].attr('string'),
    gravatar: DS['default'].attr('string'),
    photo: DS['default'].attr('string'),

    fullName: Ember.computed('displayName', 'firstName', 'lastName', function () {
      var firstName = this.get('firstNamme');
      var lastName = this.get('lastName');
      var displayName = this.get('displayName');

      if (!Ember.isEmpty(displayName)) {
        return this.get('displayName');
      } else {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
    }),

    imageUrl: Ember.computed('gravatar', 'photo', function () {
      var gravatar = this.get('gravatar');
      var photo = this.get('photo');

      if (!Ember.isEmpty(photo)) {
        return photo;
      } else {
        return gravatar;
      }
    })
  });

});