define('soundmondo-client/services/user-agent', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    isAndroidChrome: Ember['default'].computed(function () {
      return navigator.userAgent.match(/^(?=.*Android)(?=.*Chrome)/);
    })
  });

});