define('soundmondo-client/models/voice', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    reface: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    _private: DS['default'].attr('boolean', { defaultValue: true }),
    snapshot: DS['default'].attr('string'),
    trashed: DS['default'].attr('boolean'),
    voice: DS['default'].attr({ defaultValue: {} }),
    social: DS['default'].attr({ defaultValue: {} }),
    tags: DS['default'].attr(),
    created: DS['default'].attr('date'),
    updated: DS['default'].attr('date'),
    background: DS['default'].belongsTo('background', { async: true }),
    user: DS['default'].belongsTo('user', { async: true }),
    userId: DS['default'].attr('number'),
    userFullName: DS['default'].attr('string'),

    setLists: Ember['default'].computed('id', function () {
      var voiceId = this.get('id');
      return this.get('store').findQuery('set', { voices: voiceId });
    }),

    snapshotImage: Ember['default'].computed('snapshot', 'reface', function () {
      var snapshot = this.get('snapshot'),
          reface = this.get('reface'),
          snapshotImage = 'assets/images/cs.png';

      if (!Ember['default'].isBlank(snapshot)) {
        snapshotImage = snapshot;
      } else {
        snapshotImage = 'assets/images/' + reface.toLowerCase() + '.png';
      }

      if (reface === 'DX') {
        snapshotImage = 'assets/images/dx.png';
      }

      return snapshotImage;
    })
  });

});