define('soundmondo-client/services/marketing', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    marketingURL: '/marketing/',

    buildurl: function buildurl() {
      return ENV['default'].APP.API_ENDPOINT + this.get('marketingURL');
    },

    sections: Ember['default'].computed(function () {
      var PromiseObject = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin),
          url = this.buildurl();
      return PromiseObject.create({
        promise: Ember['default'].$.getJSON(url).then(function (data) {
          return data['sections'];
        })
      });
    })
  });

});