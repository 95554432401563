define('soundmondo-client/routes/voices/view', ['exports', 'ember', 'soundmondo-client/mixins/reset-scroll'], function (exports, Ember, ResetScrollMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ResetScrollMixin['default'], {
    devices: Ember['default'].inject.service('devices'),
    voiceLeaderboard: Ember['default'].inject.service('voice-leaderboard'),

    enableSync: Ember['default'].observer('devices.currentDevice.model', function () {
      var voice = this.controllerFor('voices.view').get('model.voice');
      this.setSync(voice);
    }),

    setSync: function setSync(voice) {
      var currentModel = this.get('devices.currentDevice.model');
      if (currentModel) {
        var voiceModel = voice.get('reface');
        // MODX+ は MODX と同等に扱うためここで MODX に変換する
        if (voiceModel === 'MODXPLUS') {
          voiceModel = 'MODX';
        }

        //console.log("currentMode: " + currentModel + ", voiceModel: " + voiceModel);
        var canConvert = currentModel === 'MODX' && voiceModel === 'MONTAGE' || currentModel === 'MONTAGE' || voiceModel === 'MODX';
        if (currentModel === voiceModel || canConvert) {
          this.controllerFor('application').set('canSync', true);
          this.controllerFor('application').set('voiceSyncId', voice.get('id'));
        }
      }
    },

    model: function model(params) {
      var leaderboard = this.get('voiceLeaderboard');
      return Ember['default'].RSVP.hash({
        voice: this.store.find('voice', params.voice_id),
        stats: leaderboard.byId(params.voice_id)
      });
    },

    afterModel: function afterModel(model) {
      this.setSync(model.voice);
    },

    deactivate: function deactivate() {
      this.controllerFor('application').set('canSync', false);
      this.controllerFor('application').set('voiceSyncId', -1);
    },

    resetController: function resetController(controller) {
      controller.set('incompatibleDataFormat', undefined);
    },

    actions: {
      willTransition: function willTransition() {
        this.controllerFor('application').set('canSync', false);
        this.controllerFor('application').set('voiceSyncId', -1);
        return true;
      }
    }
  });

});