define('soundmondo-client/services/user', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    store: Ember['default'].inject.service(),
    session: Ember['default'].inject.service('session'),

    authUser: Ember['default'].computed(function () {
      var userId = this.get('session.secure.id');
      return this.get('store').find('user', userId);
    })
  });

});