define('soundmondo-client/services/ranking', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        store: Ember['default'].inject.service(),

        lastMonth: Ember['default'].computed(function () {
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var now = new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 1000);
            var currentMonth = now.getMonth();
            var month = currentMonth === 0 ? 11 : currentMonth - 1;
            var currentYear = now.getFullYear();
            var year = month === 11 ? currentYear - 1 : currentYear;

            return months[month] + ' ' + year;
        })
    });

});