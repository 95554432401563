define('soundmondo-client/controllers/voices/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    devices: Ember['default'].inject.service(),
    session: Ember['default'].inject.service(),
    currentRefaceModel: Ember['default'].computed.alias('devices.currentDevice.model'),
    tags: Ember['default'].inject.service(),
    tagList: Ember['default'].computed.alias('tags.tagList'),
    titleError: null,
    descriptionError: null,
    generalError: null,
    editMode: false,

    clearErrors: function clearErrors() {
      this.setProperties({
        titleError: null,
        descriptionError: null,
        generalError: null,
        youtubeError: null,
        soundcloudError: null,
        relatedError: null
      });
    },

    handleError: function handleError(response) {
      var _this = this;
      if (!Ember['default'].isEmpty(response.errors)) {
        response.errors.forEach(function (err) {
          if (err.status === '500') {
            _this.set('generalError', 'Sorry, failed to save the sound.');
            return;
          }

          if (!err.source) {
            var message = err.detail || 'Sorry, failed to save the sound.';
            _this.set('generalError', message);
            return;
          }

          switch (err.source.pointer) {
            case '/data/attributes/name':
              _this.set('titleError', err.detail);
              break;

            case '/data/attributes/description':
              _this.set('descriptionError', err.detail);
              break;

            case '/data/attributes/social':
              if (err.detail.indexOf('YouTube') !== -1) {
                _this.set('youtubeError', err.detail);
              }

              if (err.detail.indexOf('SoundCloud') !== -1) {
                _this.set('soundcloudError', err.detail);
              }

              if (err.detail.indexOf('related') !== -1) {
                _this.set('relatedError', err.detail);
              }

              break;
            case '/data':
              if (err.detail === 'The fields user, name, reface must make a unique set.') {
                _this.set('generalError', 'A voice with this title and model already exists. Please create a voice with a unique title per reface.');
              }
              break;

            default:
              var message = err.detail || 'Sorry, failed to save the sound.';
              _this.set('generalError', message);
              break;
          }
        });
      } else {
        alert('Unable to capture voice.');
      }
    },

    setTitle: function setTitle() {
      var _this = this;
      var count = 0;
      var interval = setInterval(function () {
        window.reface_panel.getDataTitle(function (result) {
          count++;
          if (result.status) {
            _this.set('model.name', result.datatitle);
            clearInterval(interval);
          }
          if (count >= 30 && interval != null) {
            clearInterval(interval);
          }
        });
      }, 100);
    },

    addVersionCheckListener: function addVersionCheckListener() {
      window.addEventListener('versionInvalid', this.transitionToViewWithIncompatibleDataFormat.bind(this));
    },

    removeVersionCheckListener: function removeVersionCheckListener() {
      window.removeEventListener('versionInvalid', this.transitionToViewWithIncompatibleDataFormat);
    },

    transitionToViewWithIncompatibleDataFormat: function transitionToViewWithIncompatibleDataFormat() {
      var path = window.location.pathname;
      var id = path.match(/\/voices\/sync\/(\d+)/)[1];
      this.transitionToRoute('/voices/' + id + '?incompatibleDataFormat=1');
    },

    startWaiting: function startWaiting() {
      window.document.body.style.cursor = 'wait';
      window.document.getElementById("voice-save-button").style.cursor = 'wait';
    },

    finishWaiting: function finishWaiting() {
      window.document.body.style.cursor = 'default';
      window.document.getElementById("voice-save-button").style.cursor = 'pointer';
    },

    actions: {
      togglePrivate: function togglePrivate() {
        this.set('model._private', true);
      },

      togglePublic: function togglePublic() {
        this.set('model._private', false);
      },

      tagToggled: function tagToggled(tag) {
        if (!Ember['default'].isArray(this.get('model.tags'))) {
          this.set('model.tags', [this.get('model.tags')]);
        }

        if (this.get('model.tags').contains(tag.get('name'))) {
          this.get('model.tags').removeObject(tag.get('name'));
          tag.set('selected', false);
        } else {
          this.get('model.tags').pushObject(tag.get('name'));
          tag.set('selected', true);
        }
      },

      cancel: function cancel() {
        window.history.back();
      },

      save: function save() {
        var tags = this.get('model.tags'),
            _this = this,
            model = this.get('model'),
            refaceModel = this.get('currentRefaceModel'),
            user = null,
            authUserId = this.get('session.secure.id'),
            isEdit = this.get('editMode'),
            selectedTags = [];
        if (tags) {
          selectedTags = tags.filter(function (t) {
            if (t) {
              return true;
            }
          });
        }

        var refaceModelOnEdit = this.get('model.reface');

        this.clearErrors();

        if (authUserId) {
          this.store.find('user', authUserId).then(function (u) {
            user = u;

            // refaceModel の再確認
            // Cliatnサーバーでは MODX/MODX+ の切り分けが行えないため
            // Create, Edit の場合で参照先が異なる(Edit画面にrefaceのリソースが存在しない)
            if (refaceModel === 'MODX') {
              if (!isEdit) {
                refaceModel = window.reface_panel.getCurrentDevice().model;
              } else {
                refaceModel = refaceModelOnEdit;
              }
              if (/MODX\d$/.test(refaceModel)) {
                refaceModel = 'MODX';
              } else {
                refaceModel = 'MODXPLUS';
              }
            }

            _this.startWaiting();
            if (isEdit) {
              model.set('user', user);
              model.save().then(function () {
                _this.transitionTo('voices.view', model.id);
              })['catch'](_this.handleError.bind(_this))['finally'](function () {
                _this.finishWaiting();
              });
            } else {
              window.reface_panel.getVoice(function (voiceData) {
                if (voiceData.status) {
                  var data = {};
                  data['sysex'] = voiceData['sysEx'];

                  _this.get('store').createRecord('voice', {
                    name: model['name'],
                    description: model['description'],
                    _private: model['_private'],
                    reface: refaceModel,
                    tags: selectedTags,
                    voice: data,
                    social: model['social'],
                    user: user
                  }).save().then(function (voice) {
                    _this.transitionTo('voices.view', voice.id);
                  })['catch'](_this.handleError.bind(_this))['finally'](function () {
                    _this.finishWaiting();
                  });
                } else {
                  _this.finishWaiting();
                  var message = 'Sorry, failed to save the sound.';
                  if (voiceData.reason) {
                    message += ' (' + voiceData.reason + ')';
                  }
                  _this.set('generalError', message);
                }
              });
            }
          });
        }
      }
    }
  });

});