define('soundmondo-client/router', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('sign-in');
    this.route('voices', function () {
      this.route('create');
      this.route('view', { path: '/:voice_id' });
      this.route('sync', { path: '/sync/:voice_id' });
      this.route('edit', { path: '/edit/:voice_id' });
    });
    this.route('connect');
    this.route('catch-all', { path: '*:' });
    this.route('user', { path: '/user/:user_id' });
    this.route('tou');
    this.route('hoyi');
  });

  exports['default'] = Router;

});