define('soundmondo-client/tests/unit/mixins/reset-scroll-test', ['ember', 'soundmondo-client/mixins/reset-scroll', 'qunit'], function (Ember, ResetScrollMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | reset scroll');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var ResetScrollObject = Ember['default'].Object.extend(ResetScrollMixin['default']);
    var subject = ResetScrollObject.create();
    assert.ok(subject);
  });

});