define('soundmondo-client/instance-initializers/ember-i18n', ['exports', 'ember', 'ember-i18n/legacy-helper', 'ember-i18n/helper', 'soundmondo-client/config/environment'], function (exports, Ember, legacyHelper, Helper, ENV) {

  'use strict';

  exports['default'] = {
    name: 'ember-i18n',

    initialize: function initialize(instance) {
      var defaultLocale = (ENV['default'].i18n || {}).defaultLocale;
      if (defaultLocale === undefined) {
        Ember['default'].warn('ember-i18n did not find a default locale; falling back to "en".');
        defaultLocale = 'en';
      }
      instance.container.lookup('service:i18n').set('locale', defaultLocale);

      if (legacyHelper['default'] != null) {
        Ember['default'].HTMLBars._registerHelper('t', legacyHelper['default']);
      }

      if (Helper['default'] != null) {
        instance.registry.register('helper:t', Helper['default']);
      }
    }
  };

});