define('soundmondo-client/components/soundcloud-embed', ['exports', 'ember', 'soundmondo-client/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    willInsertElement: function willInsertElement() {
      var track_url = this.get('track_url'),
          _this = this;

      if (track_url) {
        SC.initialize({
          client_id: ENV['default'].APP.SOUNDCLOUD_CLIENT_ID
        });

        Ember['default'].run.scheduleOnce('sync', this, function () {
          SC.get('/resolve', { url: track_url }, function (track) {
            _this.set('track_embed_url', "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + track.id + "&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true");
          });
        });
      }
    }
  });

});