define('soundmondo-client/services/tou-agreement', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    isAgreed: Ember['default'].computed(function () {
      // Cookies.remove('agree-new-tou-and-privacy');
      if (Cookies.get('agree-new-tou-and-privacy') !== undefined) {
        return true;
      }

      return false;
    })
  });

});